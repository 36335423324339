import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import RCDatePicker from '../components/datePicker';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { translate } from 'react-internationalization';
import ReactTable from "react-table";
import SimpleReactValidator from 'simple-react-validator'
import $ from 'jquery';
import RCSelect from '../components/select';
import * as session from '../components/SessionValidator';
import { cnpj } from '../components/cpf-cnpj-validator';
import { cpf_cnpj_Mask } from '../components/mask'
import { submitReportDailyLog } from './helper';
import NumberFormat from 'react-number-format';
import * as enums from '../components/enums/customer';
import * as enumsBank from '../components/enums/bank';
import QueryString from 'query-string';

const moment = window.Moment;

class CustomerPage extends React.Component {

    constructor(props) {

        super(props);

        this.id = this.props.match.params.id;
        this.token = session.getToken();

        this.setValidators();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOnlyInt = this.handleChangeOnlyInt.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.addAddress = this.addAddress.bind(this);
        this.resetAddress = this.resetAddress.bind(this);

        this.handlechangeMask = this.handlechangeMask.bind(this)

        this.addContact = this.addContact.bind(this);
        this.resetContact = this.resetContact.bind(this);

        this.addBankAccount = this.addBankAccount.bind(this);
        this.resetBankAccount = this.resetBankAccount.bind(this);

        this.addClassification = this.addClassification.bind(this);
        this.resetClassification = this.resetClassification.bind(this);

        this.state = {

            customer: {
                portalRate: 0
            },

            countries: null,
            addressStates: null,

            contacts: {

                rowIndex: -1,
                contact: {
                    name: '',
                    position: '',
                    phoneNumber: '',
                    emails: '',
                    notes: '',
                    isActive: true,
                    isMaster: true
                }
            },

            bankAccounts: {

                rowIndex: -1,
                bankAccount: {
                    name: '',
                    BankNumber: '',
                    agencyNumber: '',
                    accountNumber: '',
                    notes: '',
                    isActive: true,
                    isMaster: true
                }
            },


            classifications: {

                rowIndex: -1,
                classification: {
                    name: '',
                    startRate: 0,
                    endRate: 0,
                    customerProfitPercentage: 50,
                    portalProfitPercentage: 50
                }
            },

            addresses: {

                rowIndex: -1,
                address: {

                    name: '',
                    legalName: '',
                    cnpj: '',
                    stateRegistration: '',
                    phoneNumber: '',
                    email: '',
                    country: {},
                    state: {},
                    city: '',
                    district: '',
                    street: '',
                    number: '',
                    complement: '',
                    zipCode: '',
                    isMaster: true
                }
            },

            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            },

            balance: { open: false },

            currentBalanceAvailable: '0,00',
            currentBalanceDueDate: ''
        };
    }

    handlechangeMask(e) {

        e.target.value = cpf_cnpj_Mask(e.target.value);
    }

    async componentDidMount() {

        let _this = this;
        let reportDailyLog = this.state.reportDailyLog;

        $('body').off('change', '.js-change').on('change', '.js-change', function (event) { _this.handleChange(event); });

        fetch(window.$CustomerServiceURL + '/api/Country/List',
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {

                res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                this.setState({ countries: res.data });
                this.forceUpdate();
            });

        if (this.id) {

            await fetch(window.$CustomerServiceURL + '/api/customer/' + this.id,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();

                })
                .then(res => {

                    reportDailyLog.objectBefore = JSON.stringify(res.data);

                    if (res.data) {

                        if (!res.data.balanceDueDate) {
                            res.data.balanceDueDate = moment;
                        }

                        this.setState({ customer: this.formatData(res.data), reportDailyLog: reportDailyLog, currentBalanceDueDate: res.data.balanceDueDate, currentBalanceAvailable: res.data.balanceAvailable });
                    }

                    this.forceUpdate();
                });

        } else {

            let customer = {

                isActive: true,
                address: [],
                contact: [],
                bankAccount: [],
                classification: [],
                balanceAvailable: '0,00',
                balanceDueDate: moment().format()
            }

            this.setState({ customer: customer });
            this.forceUpdate();
        }
    }

    async componentDidUpdate() {

        let _this = this;

        $('.js-change').each(function () { _this.setValue(this); });
        $('.js-phone-number').inputmask({ greedy: false, mask: '(99) 9999[9]-9999', showMaskOnHover: false });
        $('.js-integer').inputmask({ greedy: false, mask: '9', repeat: '*', showMaskOnHover: false });
        $('.js-decimal').inputmask('decimal', { digits: 4, digitsOptional: false, max: 999999999, placeholder: '0.00', rightAlign: false, showMaskOnHover: false });
        $('.js-letters').inputmask({ greedy: false, mask: 'a', repeat: '*', showMaskOnHover: false });
        $('.js-zipcode').inputmask({ greedy: false, mask: '99999-999', showMaskOnHover: false });
    }

    async handleChange(event) {

        session.validateSession();

        if (event.target.id === "cmbAddressCountry") {

            this.state.addresses.address.state = {};
            this.state.addresses.address.country = this.state.countries.find(item => { return item.id === event.target.value; });;
            this.state.addressStates = null;

            if (event.target.value > 0) this.loadStates('addressStates', 'addressStatesLoading', event.target.value);
        }

        if (event.target.id === "cmbAddressState") {

            this.state.addresses.address.state = this.state.addressStates.find(item => { return item.id === event.target.value; });;
        }

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    handleChangeOnlyInt(event) {

        let value = event.target.value;
        value = value.match(/\d+/g) == null ? '' : value.match(/\d+/g).join([]);
        event.target.value = value;

        this.handleChange(event);
    }

    handleTypingEmail(e) {
        e.target.value = e.target.value.toLowerCase()
    }

    setValue(element) {

        let keys = element.name.split('.'), property = this.state;

        keys.forEach(key => {

            property = property[key];
            if (Array.isArray(property)) property = property[element.dataset.index];

        });

        $(element).val(property);
    }

    formatData(data) {

        return data;
    }

    handleSubmit() {

        if (!this.validator.allValid()) {

            this.validator.showMessages();
            this.forceUpdate();

            return;
        }

        if (this.state.customer.address.length <= 0) {
            toast.error(<ToastRedirect message={translate('customer.customerAddressesRequired')} tabToShow={$('#addresses-tab-link')} />);
            $('#addresses-tab-link').addClass('invalid')
            return;
        }

        if (this.state.customer.bankAccount.length <= 0) {
            toast.error(<ToastRedirect message={translate('customer.customerBankAccountsRequired')} tabToShow={$('#bankAccounts-tab-link')} />);
            $('#bankAccounts-tab-link').addClass('invalid')
            return;
        }

        var customer = $.extend(true, {}, this.state.customer),
            method = this.id ? 'Update' : 'Create',
            status = 0;

        let reportDailyLog = this.state.reportDailyLog;

        this.setState({ submitLoading: true });

        fetch(window.$CustomerServiceURL + '/api/Customer/' + method,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                body: JSON.stringify(customer),
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                status = res.status;
                this.setState({ submitLoading: false });

                switch (status) {

                    case 401:
                        session.sessionExpired();
                        res = [];
                        return res;

                        break;

                    case 403:
                        session.accessDenied();
                        res = [];
                        return res;

                        break;

                    default:
                        return res.json();
                }

            }).then(res => {

                switch (status) {

                    case 200:
                        this.props.history.push('/customers');
                        toast.success(translate(!this.id ? 'customer.customerCreated' : 'customer.customerUpdated'));

                        reportDailyLog.action = this.id ? 3 : 2;//2 = createCustomer 3 = updateCustomer
                        reportDailyLog.objectAfter = JSON.stringify(customer);
                        submitReportDailyLog(reportDailyLog);

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });
    }

    setValidators() {

        this.validator = new SimpleReactValidator({});

        this.contactValidator = new SimpleReactValidator({

            emails: {

                rule: function (val) {

                    if (val === '') return true;
                    var emails = val.replace(new RegExp(',', 'g'), ';').split(";");
                    for (var i = 0, len = emails.length; i < len; i++) if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emails[i].trim())) return false;

                    return true;
                }
            }
        });

        this.bankAccountValidator = new SimpleReactValidator({

            bankNumber: {

                rule: (value) => {

                    return ['', null, undefined].indexOf(value) < 0;
                }
            },
        });

        this.classificationValidator = new SimpleReactValidator({

            profit: {

                rule: value => {

                    let customerProfitPercentage = parseFloat(this.state.classifications.classification.customerProfitPercentage);
                    let portalProfitPercentage = parseFloat(this.state.classifications.classification.portalProfitPercentage);

                    return (customerProfitPercentage + portalProfitPercentage) == 100;
                }
            },

            greaterThanZero: {

                rule: value => {

                    return parseFloat(value) > 0;
                }
            },

            endRateGreaterThanStartRate: {

                rule: value => {

                    let startRate = parseFloat(this.state.classifications.classification.startRate);
                    let endRate = parseFloat(this.state.classifications.classification.endRate);

                    return endRate >= startRate;
                }
            }

        });

        this.addressValidator = new SimpleReactValidator({

            state: {

                rule: value => {

                    return !this.state.addressStates || this.state.addressStates.length <= 0 || parseInt(value) > 0;
                }
            },

            cnpj: {

                rule: (value) => {

                    return cnpj.isValid(value);
                }
            },

            cnpjAdded: {

                rule: (value) => {

                    var item = this.state.customer.address ? this.state.customer.address.find(item => { return item.cnpj.replace(".", "").replace(".", "").replace("-", "").replace("/", "") === value.replace(".", "").replace(".", "").replace("-", "").replace("/", ""); }) : null;

                    return !item || (item && this.state.addresses.rowIndex >= 0);
                }
            },

            email: {

                rule: function (val) {

                    if (val === '')
                        return true;

                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val.trim()))
                        return false;

                    return true;
                }
            }

        });

        this.balanceValidator = new SimpleReactValidator({

            balanceDueDateLaterThanCurrentDate: {

                rule: () => {

                    return !this.state.currentBalanceDueDate || window.Moment(this.state.currentBalanceDueDate, 'YYYY-MM-DD').isSameOrAfter(window.Moment(new Date(), 'YYYY-MM-DD'));
                }
            },
        });
    }

    addAddress() {

        if (!this.addressValidator.allValid()) {

            this.addressValidator.showMessages();
            this.forceUpdate();
            return;
        }

        var address = JSON.parse(JSON.stringify(this.state.addresses.address));

        if (address.isMaster) {

            //desabilita todos
            for (var i = 0; i < this.state.customer.address.length; i++) {

                this.state.customer.address[i].isMaster = false;
            }
        }

        if (this.state.addresses.rowIndex < 0) {

            this.state.customer.address.push(address);

        } else {

            this.state.customer.address[this.state.addresses.rowIndex] = address;
        }

        this.resetAddress();
    }

    editAddress(rowIndex) {

        this.state.addresses.address = JSON.parse(JSON.stringify(this.state.customer.address[rowIndex]));
        this.state.addresses.rowIndex = rowIndex;

        if (this.state.customer.address[rowIndex].country.id > 0)
            this.loadStates('addressStates', 'addressStatesLoading', this.state.customer.address[rowIndex].country.id);
    }

    deleteAddress(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        var items = this.state.customer.address;
                        items.splice(rowIndex, 1);
                        this.state.customer.address = items;

                        if (this.state.addresses.rowIndex === rowIndex) {
                            this.resetAddress();

                        } else {
                            this.setState(this.state);
                        }
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('customer.customerAddressDeleteTitle'),
            title: translate('customer.customerAddressDeleteQuestion')

        });

    }

    resetAddress() {

        this.state.addressStates = null;

        this.state.addresses = {

            rowIndex: -1,
            address: {

                name: '',
                legalName: '',
                cnpj: '',
                country: {},
                state: {},
                city: '',
                district: '',
                street: '',
                number: '',
                complement: '',
                zipCode: '',
                isMaster: true,
                phoneNumber: '',
                email: '',
                stateRegistration: ''
            }
        };

        if (this.addressValidator !== undefined) {
            this.addressValidator.hideMessages();
        }

        this.setState(this.state);
    }

    addClassification() {

        if (!this.classificationValidator.allValid()) {

            this.classificationValidator.showMessages();
            this.forceUpdate();

            return;
        }

        var classification = JSON.parse(JSON.stringify(this.state.classifications.classification));

        if (this.state.classifications.rowIndex < 0) {

            this.state.customer.classification.push(classification);

        } else {

            this.state.customer.classification[this.state.classifications.rowIndex] = classification;
        }

        this.resetClassification();
    }

    editClassification(rowIndex) {

        this.state.classifications.classification = JSON.parse(JSON.stringify(this.state.customer.classification[rowIndex]));
        this.state.classifications.rowIndex = rowIndex;

        this.setState(this.state);
    }

    deleteClassification(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        var items = this.state.customer.classification;
                        items.splice(rowIndex, 1);
                        this.state.customer.classification = items;

                        if (this.state.classifications.rowIndex === rowIndex) { this.resetClassification(); } else { this.setState(this.state); }
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('customer.customerClassificationDeleteTitle'),
            title: translate('customer.customerClassificationDeleteQuestion')
        });

    }

    resetClassification() {

        this.state.classifications = {

            rowIndex: -1,
            classification: {
                name: '',
                startRate: 0,
                endRate: 0,
                customerProfitPercentage: 50,
                portalProfitPercentage: 50
            }
        };

        if (this.classificationValidator !== undefined) {
            this.classificationValidator.hideMessages();
        }

        this.setState(this.state);
    }

    addBankAccount() {

        if (!this.bankAccountValidator.allValid()) {

            this.bankAccountValidator.showMessages();
            this.forceUpdate();

            return;
        }

        var bankAccount = JSON.parse(JSON.stringify(this.state.bankAccounts.bankAccount));

        if (bankAccount.isMaster) {

            //desabilita todos
            for (var i = 0; i < this.state.customer.bankAccount.length; i++) {

                this.state.customer.bankAccount[i].isMaster = false;
            }
        }

        if (this.state.bankAccounts.rowIndex < 0) {

            this.state.customer.bankAccount.push(bankAccount);

        } else {

            this.state.customer.bankAccount[this.state.bankAccounts.rowIndex] = bankAccount;
        }

        this.resetBankAccount();
    }

    editBankAccount(rowIndex) {

        this.state.bankAccounts.bankAccount = JSON.parse(JSON.stringify(this.state.customer.bankAccount[rowIndex]));
        this.state.bankAccounts.rowIndex = rowIndex;

        this.setState(this.state);
    }

    deleteBankAccount(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        var items = this.state.customer.bankAccount;
                        items.splice(rowIndex, 1);
                        this.state.customer.bankAccount = items;

                        if (this.state.bankAccounts.rowIndex === rowIndex) { this.resetBankAccount(); } else { this.setState(this.state); }
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('customer.customerSetupAccountDeleteTitle'),
            title: translate('customer.customerSetupAccountDeleteQuestion')
        });

    }

    resetBankAccount() {
        this.state.bankAccounts = {

            rowIndex: -1,
            bankAccount: {
                name: '',
                BankNumber: '',
                agencyNumber: '',
                accountNumber: '',
                notes: '',
                isActive: true,
                isMaster: true
            }
        };

        if (this.bankAccountValidator !== undefined) {
            this.bankAccountValidator.hideMessages();
        }

        this.setState(this.state);
    }

    addContact() {

        if (!this.contactValidator.allValid()) {

            this.contactValidator.showMessages();
            this.forceUpdate();

            return;
        }

        var contact = JSON.parse(JSON.stringify(this.state.contacts.contact));

        if (contact.isMaster) {

            //desabilita todos
            for (var i = 0; i < this.state.customer.contact.length; i++) {

                this.state.customer.contact[i].isMaster = false;
            }
        }

        if (this.state.contacts.rowIndex < 0) {

            this.state.customer.contact.push(contact);

        } else {

            this.state.customer.contact[this.state.contacts.rowIndex] = contact;
        }

        this.resetContact();
    }

    editContact(rowIndex) {

        this.state.contacts.contact = JSON.parse(JSON.stringify(this.state.customer.contact[rowIndex]));
        this.state.contacts.rowIndex = rowIndex;

        this.setState(this.state);
    }

    deleteContact(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        var items = this.state.customer.contact;
                        items.splice(rowIndex, 1);
                        this.state.customer.contact = items;

                        if (this.state.contacts.rowIndex === rowIndex) { this.resetContact(); } else { this.setState(this.state); }
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('customer.customerContactDeleteTitle'),
            title: translate('customer.customerContactDeleteQuestion')
        });

    }

    resetContact() {

        this.state.contacts = {

            rowIndex: -1,
            contact: {
                name: '',
                position: '',
                phoneNumber: '',
                emails: '',
                notes: '',
                isActive: true,
                isMaster: true
            }
        };

        if (this.contactValidator !== undefined) {
            this.contactValidator.hideMessages();
        }

        this.setState(this.state);
    }

    async loadStates(states, statesLoading, countryId) {

        this.state[statesLoading] = true;
        this.setState(this.state);

        fetch(window.$CustomerServiceURL + '/api/State/List?countryId=' + countryId,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {

                this.state[states] = res.data;
                this.state[statesLoading] = false;

                this.setState(this.state);
                this.forceUpdate();

            });
    }

    handleEditBalance() {

        this.state.balance.open = true;
        this.state.balanceBackup = $.extend(true, {}, this.state.balance);

        this.balanceValidator.hideMessages();
        this.forceUpdate();
    }

    handleSaveBalance() {

        let valid = this.balanceValidator.allValid();

        if (valid) {

            let balance = this.state.balance;

            this.setState({ balance: balance, balanceBackup: undefined });

            let queryUpdateBalance = {};

            //format
            let balanceAvailable = 0;

            if (this.state.currentBalanceAvailable.toString().includes("R$")) 
                balanceAvailable = parseFloat(this.state.currentBalanceAvailable.toString().replace('R$ ', '').replace(/\./g, '').replace(',', '.'));
            else
                balanceAvailable = this.state.currentBalanceAvailable;

            queryUpdateBalance.id = this.id;
            queryUpdateBalance.value = balanceAvailable;
            queryUpdateBalance.dueDate = this.state.currentBalanceDueDate;

            this.setState({ submitLoading: true });

            fetch(window.$CustomerServiceURL + '/api/Customer/UpdateBalanceAvailable/?' + QueryString.stringify(queryUpdateBalance),
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    switch (res.status) {

                        case 401:
                            session.sessionExpired();
                            res = [];
                            return res;

                            break;

                        case 403:
                            session.accessDenied();
                            res = [];
                            return res;

                            break;

                        default:
                            return res.json();
                    }

                }).then(res => {

                    switch (res.code) {

                        case 200:
                            toast.success(translate('customer.customerBalanceUpdated'));

                            break;

                        case 400:
                        case 409:
                            var messages = res.data;
                            messages.forEach(ex => toast.warn(ex.message));
                            break;

                        case 500:
                            toast.error('error');
                            break;
                    }

                    balance.open = false;
                    this.setState({ submitLoading: false, balance: balance });

                }).catch(err => {
                    console.error(err);
                });

        } else {

            this.balanceValidator.showMessages();
            this.forceUpdate();
        }
    }

    handleReturnBalance() {

        let balance = $.extend(true, {}, this.state.balanceBackup);
        balance.open = false;

        this.setState({ balance: balance, balanceBackup: undefined });
    }

    render() {

        var fetched = this.state != null && this.state.customer != null && this.state.countries != null;

        if (fetched) {

            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    <ul className="nav nav-tabs tab-nav-right" role="tablist">
                                        <li className="active" role="presentation">
                                            <a href="#customerPanel" data-toggle="tab">{translate('customer.customerTitle')}</a>
                                        </li>

                                        <li role="presentation">
                                            <a id="addresses-tab-link" href="#addressPanel" data-toggle="tab">{translate('customer.customerAddressNameTitle')}</a>
                                        </li>

                                        <li role="presentation">
                                            <a href="#contactsPanel" data-toggle="tab">{translate('customer.customerContactsTitle')}</a>
                                        </li>

                                        <li role="presentation">
                                            <a id="bankAccounts-tab-link" href="#bankAccountsPanel" data-toggle="tab">{translate('customer.customerSetupAccountTitle')}</a>
                                        </li>

                                        <li role="presentation">
                                            <a href="#classificationsPanel" data-toggle="tab">{translate('customer.customerClassificationsTitle')}</a>
                                        </li>

                                    </ul>
                                </h2>

                                <div className="header-dropdown">
                                    <div className="preloader pl-size-xs" style={{ display: this.state.submitLoading ? 'block' : 'none' }}>
                                        <div className="spinner-layer">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>

                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <div className="tab-content">
                                    <div id="customerPanel" className="tab-pane fade in active" role="tabpanel">
                                        <div>
                                            <div className="m-b-40">
                                                <small>{translate('customer.customerTitleDescription')}</small>
                                            </div>

                                            <div className="row clearfix">
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <label className="validationRequired" htmlFor="txtName">{translate('customer.customerName')}</label>

                                                        <div className="form-line">
                                                            <input id="txtName" disabled={!session.auth([{ type: "Customer", value: "Save" }])} className="form-control js-change" name="customer.name" placeholder={translate('customer.customerNamePlaceHolder')} type="text" />
                                                        </div>

                                                        {this.validator.message('customer.name', this.state.customer.name, 'required', 'validationMessage--error', { default: translate('customer.customerNameRequired') })}
                                                    </div>
                                                </div>

                                                {(session.isAdmin() || session.isOperator()) &&
                                                    <div className="col-md-3">
                                                        <div className="m-t-30">
                                                            <input id="chkIsActive" name="customer.isActive" type="checkbox" checked={this.state.customer.isActive} onChange={this.handleChange} />
                                                            <label htmlFor="chkIsActive">{translate('customer.customerIsActive')}</label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="row clearfix">

                                                <div className="col-md-3">
                                                    <div className="m-b-20">
                                                        <label htmlFor="cmbTypeApproval" className="validationRequired">
                                                            {translate('customer.customerTypeApproval')}
                                                        </label>

                                                        <RCSelect
                                                            id="cmbTypeApproval"
                                                            name="customer.typeApproval"
                                                            hasEmptyOption={true}
                                                            isInt={true}
                                                            options={enums.getTypeApproval()}
                                                            optionValue="index"
                                                            optionLabel="text"
                                                            placeholder={translate('customer.customerTypeApprovalPlaceHolder')}
                                                            onChange={event => { this.handleChange(event); }}
                                                            value={this.state.customer.typeApproval}
                                                            isDisabled={!session.auth([{ type: "Customer", value: "Save" }])}
                                                        />

                                                        {this.validator.message('cmbTypeApproval', this.state.customer.typeApproval, 'required', false, { default: translate('customer.customerTypeApprovalRequired') })}
                                                    </div>
                                                </div>

                                                {(session.isAdmin() || session.isOperator()) &&
                                                    <div className="col-md-3">
                                                        <div className="input-group">
                                                            <label htmlFor="txtPortalRate">{translate('customer.customerPortalRate')}</label>

                                                            <div className="form-line">
                                                                <input id="txtPortalRate" className="form-control js-change js-decimal" name="customer.portalRate" placeholder={translate('customer.customerPortalRatePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.validator.message('customer.portalRate', this.state.customer.portalRate, 'gt: 0', false, { default: translate('customer.customerPortalRateRequired') })}
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                        </div>
                                    </div>

                                    <div id="addressPanel" className="tab-pane fade in" role="tabpanel">
                                        {
                                            session.auth([{ type: "Customer", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('customer.customerAddressTitleDescription')}</small>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-10">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtAddressName">{translate('customer.customerAddressName')}</label>

                                                            <div className="form-line">
                                                                <input id="txtAddressName" name="addresses.address.name" className="form-control js-change" placeholder={translate('customer.customerAddressNamePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.addressValidator.message('addresses.address.name', this.state.addresses.address.name, 'required', false, { default: translate('customer.customerAddressNameRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="m-t-30">
                                                            <input id="chkIsMaster" name="addresses.address.isMaster" type="checkbox" checked={this.state.addresses.address.isMaster} onChange={this.handleChange} />
                                                            <label htmlFor="chkIsMaster">{translate('customer.customerAddressIsMaster')}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">

                                                    <div className="col-md-9">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtAddressLegalName">{translate('customer.customerLegalName')}</label>

                                                            <div className="form-line">
                                                                <input id="txtAddressLegalName" name="addresses.address.legalName" className="form-control js-change" autoComplete="off" maxLength='100' placeholder={translate('customer.customerLegalNamePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.addressValidator.message('addresses.address.legalName', this.state.addresses.address.legalName, 'required', false, { default: translate('customer.customerLegalNameRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="input-group">
                                                            <label htmlFor="txtAddressCnpj" className="validationRequired">{translate('customer.customerCNPJ')}</label>

                                                            <div className="form-line">
                                                                <input id="txtAddressCnpj" name="addresses.address.cnpj" maxLength='19' onChange={this.handlechangeMask} className="form-control js-change" autoComplete="off" placeholder={translate('customer.customerCNPJPlaceHolder')} type="text" />
                                                            </div>

                                                            {this.addressValidator.message('addresses.address.cnpj', this.state.addresses.address.cnpj, 'required|cnpj|cnpjAdded', false, { default: translate('customer.customerCNPJRequired'), cnpj: translate('customer.customerCNPJInvalid'), cnpjAdded: translate('customer.customerCnpjAdded') })}
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtStreetName">{translate('customer.customerAddressStreet')}</label>

                                                            <div className="form-line">
                                                                <input id="txtStreetName" name="addresses.address.street" className="form-control js-change" placeholder={translate('customer.customerAddressStreetPlaceHolder')} type="text" />
                                                            </div>

                                                            {this.addressValidator.message('addresses.address.street', this.state.addresses.address.street, 'required', false, { default: translate('customer.customerAddressStreetRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label htmlFor="txtStreetNumber">{translate('customer.customerAddressNumber')}</label>

                                                            <div className="form-line">
                                                                <input id="txtStreetNumber" name="addresses.address.number" className="form-control js-change js-integer" placeholder={translate('customer.customerAddressNumberPlaceHolder')} type="text" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label htmlFor="txtDistrict">{translate('customer.customerAddressDistrict')}</label>

                                                            <div className="form-line">
                                                                <input id="txtDistrict" name="addresses.address.district" className="form-control js-change" placeholder={translate('customer.customerAddressDistrictPlaceHolder')} type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtZipCode">{translate('customer.customerAddressZipCode')}</label>

                                                            <div className="form-line">
                                                                <input id="txtZipCode" name="addresses.address.zipCode" className="form-control js-change js-zipcode" placeholder={translate('customer.customerAddressZipCodePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.addressValidator.message('addresses.address.zipCode', this.state.addresses.address.zipCode, 'required', false, { default: translate('customer.customerAddressZipCodeRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtCity">{translate('customer.customerAddressCity')}</label>

                                                            <div className="form-line">
                                                                <input id="txtCity" name="addresses.address.city" className="form-control js-change" placeholder={translate('customer.customerAddressCityPlaceHolder')} type="text" />
                                                            </div>

                                                            {this.addressValidator.message('addresses.address.city', this.state.addresses.address.city, 'required', false, { default: translate('customer.customerAddressCityRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label htmlFor="txtComplement">{translate('customer.customerAddressComplement')}</label>

                                                            <div className="form-line">
                                                                <input id="txtComplement" name="addresses.address.complement" className="form-control js-change" placeholder={translate('customer.customerAddressComplementPlaceHolder')} type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-6">
                                                        <div className="m-b-20">
                                                            <label className="validationRequired" htmlFor="cmbAddressCountry">{translate('customer.customerAddressCountry')}</label>

                                                            <RCSelect
                                                                id="cmbAddressCountry"
                                                                name="addresses.address.country.id"
                                                                hasEmptyOption={true}
                                                                isInt={true}
                                                                options={this.state.countries}
                                                                optionValue="id"
                                                                optionLabel="name"
                                                                placeholder={translate('customer.customerAddressCountryPlaceHolder')}
                                                                onChange={event => { this.handleChange(event); }}
                                                                value={this.state.addresses.address.country.id}
                                                            />

                                                            {this.addressValidator.message('addresses.address.country.id', this.state.addresses.address.country.id, 'gt: 0', false, { default: translate('customer.customerAddressCountryRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="m-b-20">
                                                            <label htmlFor="cmbAddressState" className={this.state.addressStates && this.state.addressStates.length > 0 ? 'validationRequired' : ''}>
                                                                {translate('customer.customerAddressState')}
                                                            </label>

                                                            <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.addressStatesLoading ? 'inline-block' : 'none' }}>
                                                                <div className="spinner-layer">
                                                                    <div className="circle-clipper left">
                                                                        <div className="circle"></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <RCSelect
                                                                id="cmbAddressState"
                                                                name="addresses.address.state.id"
                                                                hasEmptyOption={true}
                                                                isInt={true}
                                                                options={this.state.addressStates != null ? this.state.addressStates : this.state.states}
                                                                optionValue="id"
                                                                optionLabel="displayName"
                                                                placeholder={translate('customer.customerAddressStatePlaceHolder')}
                                                                onChange={event => { this.handleChange(event); }}
                                                                value={this.state.addresses.address.state.id}
                                                            />

                                                            {this.addressValidator.message('cmbAddressState', this.state.addresses.address.state.id, 'gt: 0', false, { default: translate('customer.customerAddressStateRequired') })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">

                                                    <div className="col-md-3">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtAddressPhoneNumber">{translate('customer.customerContactPhoneNumber')}</label>

                                                            <div className="form-line">
                                                                <input id="txtAddressPhoneNumber" name="addresses.address.phoneNumber" maxLength="15" className="form-control js-change js-phone-number" placeholder={translate('customer.customerContactPhoneNumberPlaceHolder')} type="text" />
                                                            </div>

                                                            {this.addressValidator.message('addresses.address.phoneNumber', this.state.addresses.address.phoneNumber, 'required', false, { default: translate('customer.customerContactPhoneNumberRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-5">
                                                        <div className="input-group">
                                                            <label htmlFor="txtAddressEmail">{translate('customer.customerEmail')}</label>

                                                            <div className="form-line">
                                                                <input id="txtAddressEmail" className="form-control js-change" name="addresses.address.email" placeholder={translate('customer.customerEmailPlaceHolder')} onKeyUp={this.handleTypingEmail} type="text" />
                                                            </div>

                                                            {this.addressValidator.message('addresses.address.email', this.state.addresses.address.email, 'email', false, { default: translate('customer.customerEmailInvalid') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label htmlFor="txtStateRegistration"> {translate('customer.customerStateRegistration')} </label>

                                                            <div className="form-line">
                                                                <input id="txtStateRegistration" name="addresses.address.stateRegistration" className="form-control js-change" autoComplete="off" placeholder={translate('customer.customerStateRegistrationPlaceHolder')} type="text" />
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <button type="button" className="btn m-r-10" onClick={this.resetAddress} style={{ display: this.state.addresses.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                            <i className="material-icons">&#xe5c4;</i>
                                                            <span>{translate('forms.buttonCancel')}</span>
                                                        </button>

                                                        <button type="button" className="btn btn-primary" onClick={this.addAddress}>
                                                            <i className="material-icons">&#xe145;</i>
                                                            <span>{translate(this.state.addresses.rowIndex < 0 ? 'customer.customerAddressesAddNew' : 'customer.customerAddressesUpdate')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <ReactTable
                                            data={this.state.customer.address}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('customer.customerAddressName'),
                                                            accessor: "name",
                                                        },
                                                        {
                                                            Header: translate('customer.customerCNPJ'),
                                                            accessor: "cnpj",
                                                        },
                                                        {
                                                            Header: translate('customer.customerAddressState'),
                                                            accessor: "state.name",

                                                        },
                                                        {
                                                            Header: translate('customer.customerAddressCity'),
                                                            accessor: "city",

                                                        },
                                                        {
                                                            Header: translate('customer.customerAddressDistrict'),
                                                            accessor: "district",

                                                        },
                                                        {
                                                            Header: translate('customer.customerAddressIsMaster'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isMaster &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isMaster &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        session.auth([{ type: "Customer", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteAddress(row.index) }}>
                                                                        <i className="material-icons">&#xe872;</i>
                                                                    </button>

                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editAddress(row.index) }}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            SubComponent={row => {
                                                return <div className="m-l-30 m-r-30 m-t-30">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerAddressCountry')}:
																<b> {row.original.country.name}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <p>
                                                                {translate('customer.customerLegalName')}:
																<b> {row.original.legalName}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <p>
                                                                {translate('customer.customerAddressStreet')}:
																<b> {row.original.street}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerAddressZipCode')}:
																<b> {row.original.zipCode}</b>
                                                            </p>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <p>
                                                                {translate('customer.customerAddressNumber')}:
																<b> {row.original.number}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <p>
                                                                {translate('customer.customerAddressComplement')}:
																<b> {row.original.complement}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerContactPhoneNumber')}:
																<b> {row.original.phoneNumber}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <p>
                                                                {translate('customer.customerEmail')}:
																<b> {row.original.email}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <p>
                                                                {translate('customer.customerStateRegistration')}:
																<b> {row.original.stateRegistration}</b>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            }}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}
                                        />
                                    </div>

                                    <div id="contactsPanel" className="tab-pane fade in" role="tabpanel">
                                        {
                                            session.auth([{ type: "Customer", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('customer.customerContactsTitleDescription')}</small>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtContactName">{translate('customer.customerContactName')}</label>

                                                            <div className="form-line">
                                                                <input id="txtContactName" className="form-control js-change" name="contacts.contact.name" placeholder={translate('customer.customerContactNamePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.contactValidator.message('contacts.contact.name', this.state.contacts.contact.name, 'required', false, { default: translate('customer.customerContactNameRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label htmlFor="txtContactPosition">{translate('customer.customerContactPosition')}</label>

                                                            <div className="form-line">
                                                                <input id="txtContactPosition" className="form-control js-change" name="contacts.contact.position" placeholder={translate('customer.customerContactPositionPlaceHolder')} type="text" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="m-t-30">
                                                            <input id="chkContactIsMaster" name="contacts.contact.isMaster" type="checkbox" checked={this.state.contacts.contact.isMaster} onChange={this.handleChange} />
                                                            <label htmlFor="chkContactIsMaster">{translate('customer.customerContactIsMaster')}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="m-t-30">
                                                            <input id="chkContactIsActive" name="contacts.contact.isActive" type="checkbox" checked={this.state.contacts.contact.isActive} onChange={this.handleChange} />
                                                            <label htmlFor="chkContactIsActive">{translate('customer.customerIsActive')}</label>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row clearfix">

                                                    <div className="col-md-3">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtContactPhoneNumber">{translate('customer.customerContactPhoneNumber')}</label>

                                                            <div className="form-line">
                                                                <input id="txtContactPhoneNumber" length="15" className="form-control js-change js-phone-number" name="contacts.contact.phoneNumber" placeholder={translate('customer.customerContactPhoneNumberPlaceHolder')} type="text" />
                                                            </div>

                                                            {this.contactValidator.message('contacts.contact.phoneNumber', this.state.contacts.contact.phoneNumber, 'required', false, { default: translate('customer.customerContactPhoneNumberRequired') })}
                                                        </div>
                                                    </div>


                                                    <div className="col-md-5">
                                                        <div className="input-group">
                                                            <label htmlFor="txtContactEmails">{translate('customer.customerContactEmails')}</label>

                                                            <div className="form-line">
                                                                <input id="txtContactEmails" className="form-control js-change" name="contacts.contact.emails" placeholder={translate('customer.customerContactEmailsPlaceHolder')} onKeyUp={this.handleTypingEmail} type="text" />
                                                            </div>

                                                            {this.contactValidator.message('contacts.contact.emails', this.state.contacts.contact.emails, 'emails', false, { default: translate('customer.customerContactEmailsInvalid') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label htmlFor="txtContactNotes">{translate('customer.customerContactNotes')}</label>

                                                            <div className="form-line">
                                                                <input id="txtContactNotes" className="form-control js-change" name="contacts.contact.notes" placeholder={translate('customer.customerContactNotesPlaceHolder')} type="text" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <button type="button" className="btn m-r-10" onClick={this.resetContact} style={{ display: this.state.contacts.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                            <i className="material-icons">&#xe5c4;</i>
                                                            <span>{translate('forms.buttonCancel')}</span>
                                                        </button>

                                                        <button type="button" className="btn btn-primary" onClick={this.addContact}>
                                                            <i className="material-icons">&#xe145;</i>
                                                            <span>{translate(this.state.contacts.rowIndex < 0 ? 'customer.customerContactsAddNew' : 'customer.customerContactsUpdate')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <ReactTable
                                            data={this.state.customer.contact}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('customer.customerContactName'),
                                                            id: "name",
                                                            accessor: data => { return <span title={data.name}> {data.name} </span> }
                                                        },
                                                        {
                                                            Header: translate('customer.customerContactPosition'),
                                                            accessor: "position",
                                                        },
                                                        {
                                                            Header: translate('customer.customerContactPhoneNumber'),
                                                            accessor: "phoneNumber",
                                                        },
                                                        {
                                                            Header: translate('customer.customerContactEmails'),
                                                            id: "emails",
                                                            accessor: data => { return <span title={data.emails}> {data.emails} </span> }
                                                        },
                                                        {
                                                            Header: translate('customer.customerContactNotes'),
                                                            id: "notes",
                                                            accessor: data => { return <span title={data.notes}> {data.notes} </span> }
                                                        },
                                                        {
                                                            Header: translate('customer.customerContactIsMaster'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isMaster &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isMaster &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: translate('customer.customerIsActive'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isActive &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isActive &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        session.auth([{ type: "Customer", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteContact(row.index) }}>
                                                                        <i className="material-icons">&#xe872;</i>
                                                                    </button>

                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editContact(row.index) }}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}
                                        />
                                    </div>

                                    <div id="bankAccountsPanel" className="tab-pane fade in" role="tabpanel">
                                        {
                                            session.auth([{ type: "Customer", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('customer.customerSetupAccountTitleDescription')}</small>
                                                    </div>
                                                </div>


                                                <div className="row clearfix">

                                                    <div className="col-md-4">
                                                        <div className="m-b-20">
                                                            <label htmlFor="cmbBankNumber" className="validationRequired">
                                                                {translate('customer.customerSetupAccountBankNumber')}
                                                            </label>

                                                            <RCSelect
                                                                id="cmbBankNumber"
                                                                name="bankAccounts.bankAccount.bankNumber"
                                                                hasEmptyOption={true}
                                                                isInt={false}
                                                                options={enumsBank.getBankNumber()}
                                                                optionValue="index"
                                                                optionLabel="text"
                                                                placeholder={translate('customer.customerSetupAccountBankNumberPlaceHolder')}
                                                                onChange={event => { this.handleChange(event); }}
                                                                value={this.state.bankAccounts.bankAccount.bankNumber}
                                                            />

                                                            {this.bankAccountValidator.message('cmbBankNumber', this.state.bankAccounts.bankAccount.bankNumber, 'required|bankNumber', false, { default: translate('customer.customerSetupAccountBankNumberRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtBankAccountName">{translate('customer.customerSetupAccountName')}</label>

                                                            <div className="form-line">
                                                                <input id="txtBankAccountName" maxLength="200" className="form-control js-change" name="bankAccounts.bankAccount.name" placeholder={translate('customer.customerSetupAccountNamePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.bankAccountValidator.message('bankAccounts.bankAccount.name', this.state.bankAccounts.bankAccount.name, 'required', false, { default: translate('customer.customerSetupAccountNameRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="m-t-30">
                                                            <input id="chkBankAccountIsMaster" name="bankAccounts.bankAccount.isMaster" type="checkbox" checked={this.state.bankAccounts.bankAccount.isMaster} onChange={this.handleChange} />
                                                            <label htmlFor="chkBankAccountIsMaster">{translate('customer.customerSetupAccountIsMaster')}</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="m-t-30">
                                                            <input id="chkBankAccountIsActive" name="bankAccounts.bankAccount.isActive" type="checkbox" checked={this.state.bankAccounts.bankAccount.isActive} onChange={this.handleChange} />
                                                            <label htmlFor="chkBankAccountIsActive">{translate('customer.customerSetupAccountIsActive')}</label>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row clearfix">

                                                    <div className="col-md-3">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtBankAccountAgencyNumber">{translate('customer.customerSetupAccountAgencyNumber')}</label>

                                                            <div className="form-line">
                                                                <input id="txtBankAccountAgencyNumber" maxLength="4" className="form-control js-change js-integer" name="bankAccounts.bankAccount.agencyNumber" placeholder={translate('customer.customerSetupAccountAgencyNumberPlaceHolder')} type="text" />
                                                            </div>

                                                            {this.bankAccountValidator.message('bankAccounts.bankAccount.agencyNumber', this.state.bankAccounts.bankAccount.agencyNumber, 'required', false, { default: translate('customer.customerSetupAccountAgencyNumberRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="input-group">
                                                            <label htmlFor="txtBankAccountNumber">{translate('customer.customerSetupAccountNumber')}</label>

                                                            <div className="form-line">
                                                                <input id="txtBankAccountNumber" maxLength="20" className="form-control js-change js-integer" name="bankAccounts.bankAccount.accountNumber" placeholder={translate('customer.customerSetupAccountNumberPlaceHolder')} type="text" />
                                                            </div>

                                                            {this.bankAccountValidator.message('bankAccounts.bankAccount.accountNumber', this.state.bankAccounts.bankAccount.accountNumber, 'required', false, { default: translate('customer.customerSetupAccountNumberRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <label htmlFor="txtBankAccountsNotes">{translate('customer.customerSetupAccountNotes')}</label>

                                                            <div className="form-line">
                                                                <input id="txtBankAccountsNotes" className="form-control js-change" name="bankAccounts.bankAccount.notes" placeholder={translate('customer.customerSetupAccountNotesPlaceHolder')} type="text" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <button type="button" className="btn m-r-10" onClick={this.resetBankAccount} style={{ display: this.state.bankAccounts.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                            <i className="material-icons">&#xe5c4;</i>
                                                            <span>{translate('forms.buttonCancel')}</span>
                                                        </button>

                                                        <button type="button" className="btn btn-primary" onClick={this.addBankAccount}>
                                                            <i className="material-icons">&#xe145;</i>
                                                            <span>{translate(this.state.bankAccounts.rowIndex < 0 ? 'customer.customerSetupAccountsAddNew' : 'customer.customerSetupAccountsUpdate')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <ReactTable
                                            data={this.state.customer.bankAccount}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('customer.customerSetupAccountName'),
                                                            id: "name",
                                                            accessor: data => { return <span title={data.name}> {data.name} </span> }
                                                        },
                                                        {
                                                            Header: translate('customer.customerSetupAccountBankNumber'),
                                                            id: "bankNumber",
                                                            accessor: data => { return <span title={enumsBank.getBankNumber().find(x => { return x.index === data.bankNumber; }).text}> {enumsBank.getBankNumber().find(x => { return x.index === data.bankNumber; }).text} </span> }
                                                        },
                                                        {
                                                            Header: translate('customer.customerSetupAccountAgencyNumber'),
                                                            accessor: "agencyNumber",
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: translate('customer.customerSetupAccountNumber'),
                                                            accessor: "accountNumber",
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: translate('customer.customerSetupAccountNotes'),
                                                            id: "notes",
                                                            accessor: data => { return <span title={data.notes}> {data.notes} </span> }
                                                        },
                                                        {
                                                            Header: translate('customer.customerSetupAccountIsMaster'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isMaster &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isMaster &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: translate('customer.customerSetupAccountIsActive'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isActive &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isActive &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        session.auth([{ type: "Customer", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteBankAccount(row.index) }}>
                                                                        <i className="material-icons">&#xe872;</i>
                                                                    </button>

                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editBankAccount(row.index) }}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}
                                        />
                                    </div>

                                    <div id="classificationsPanel" className="tab-pane fade in" role="tabpanel">
                                        {
                                            (session.isAdmin() || session.isOperator()) && session.auth([{ type: "Customer", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('customer.customerClassificationsTitleDescription')}</small>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label className="validationRequired" htmlFor="txtClassificationName">{translate('customer.customerClassificationName')}</label>

                                                            <div className="form-line">
                                                                <input id="txtClassificationName" className="form-control js-change" name="classifications.classification.name" placeholder={translate('customer.customerClassificationNamePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.classificationValidator.message('classifications.classification.name', this.state.classifications.classification.name, 'required', false, { default: translate('customer.customerClassificationNameRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label htmlFor="txtClassificationStartRate">{translate('customer.customerClassificationStartRate')}</label>

                                                            <div className="form-line">
                                                                <input id="txtClassificationStartRate" className="form-control js-change js-decimal" name="classifications.classification.startRate" placeholder={translate('customer.customerClassificationStartRatePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.classificationValidator.message('classifications.classification.startRate', this.state.classifications.classification.startRate, 'required|greaterThanZero|endRateGreaterThanStartRate', false, { default: translate('customer.customerClassificationStartRateRequired'), greaterThanZero: translate('customer.customerClassificationRateGreaterThanZero'), endRateGreaterThanStartRate: translate('customer.customerClassificationEndRateGreaterThanStartRate') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group">
                                                            <label htmlFor="txtClassificationEndRate">{translate('customer.customerClassificationEndRate')}</label>

                                                            <div className="form-line">
                                                                <input id="txtClassificationEndRate" className="form-control js-change js-decimal" name="classifications.classification.endRate" placeholder={translate('customer.customerClassificationEndRatePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.classificationValidator.message('classifications.classification.endRate', this.state.classifications.classification.endRate, 'required|greaterThanZero|endRateGreaterThanStartRate', false, { default: translate('customer.customerClassificationEndRateRequired'), greaterThanZero: translate('customer.customerClassificationRateGreaterThanZero'), endRateGreaterThanStartRate: translate('customer.customerClassificationEndRateGreaterThanStartRate') })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">

                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <label htmlFor="txtClassificationCustomerProfitPercentage">{translate('customer.customerClassificationCustomerProfitPercentage')}</label>

                                                            <div className="form-line">
                                                                <input id="txtClassificationCustomerProfitPercentage" className="form-control js-change js-decimal" name="classifications.classification.customerProfitPercentage" placeholder={translate('customer.customerClassificationCustomerProfitPercentagePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.classificationValidator.message('classifications.classification.customerProfitPercentage', this.state.classifications.classification.customerProfitPercentage, 'required|profit', false, { default: translate('customer.customerClassificationCustomerProfitPercentageRequired'), profit: translate('customer.customerClassificationProfitPercentage100') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-group">
                                                            <label htmlFor="txtClassificationPortalProfitPercentage">{translate('customer.customerClassificationPortalProfitPercentage')}</label>

                                                            <div className="form-line">
                                                                <input id="txtClassificationPortalProfitPercentage" className="form-control js-change js-decimal" name="classifications.classification.portalProfitPercentage" placeholder={translate('customer.customerClassificationPortalProfitPercentagePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.classificationValidator.message('classifications.classification.portalProfitPercentage', this.state.classifications.classification.portalProfitPercentage, 'required|profit', false, { default: translate('customer.customerClassificationPortalProfitPercentageRequired'), profit: translate('customer.customerClassificationProfitPercentage100') })}
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <button type="button" className="btn m-r-10" onClick={this.resetClassification} style={{ display: this.state.classifications.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                            <i className="material-icons">&#xe5c4;</i>
                                                            <span>{translate('forms.buttonCancel')}</span>
                                                        </button>

                                                        <button type="button" className="btn btn-primary" onClick={this.addClassification}>
                                                            <i className="material-icons">&#xe145;</i>
                                                            <span>{translate(this.state.classifications.rowIndex < 0 ? 'customer.customerClassificationsAddNew' : 'customer.customerClassificationsUpdate')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <ReactTable
                                            data={this.state.customer.classification}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('customer.customerClassificationName'),
                                                            accessor: "name",
                                                        },
                                                        {
                                                            Header: translate('customer.customerClassificationStartRate'),
                                                            Cell: row => { return row.original.startRate + '%' }
                                                        },
                                                        {
                                                            Header: translate('customer.customerClassificationEndRate'),
                                                            Cell: row => { return row.original.endRate + '%' }
                                                        },
                                                        (session.isAdmin() || session.isOperator()) && session.auth([{ type: "Customer", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteClassification(row.index) }}>
                                                                        <i className="material-icons">&#xe872;</i>
                                                                    </button>

                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editClassification(row.index) }}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}
                                        />
                                    </div>

                                </div>

                                <div className="row clearfix">
                                    <div className="col-md-12 formActions">
                                        <Link to="/customers" className="btn btn-default waves-effect">
                                            <i className="material-icons">&#xe5c4;</i>
                                            <span>{translate('forms.buttonReturn')}</span>
                                        </Link>

                                        {(session.isAdmin() || session.isOperator()) && session.auth([{ type: "Customer", value: "Save" }]) &&
                                            <button className="btn bg-deep-purple waves-effect m-l-10" style={{ display: this.id ? 'inline-block' : 'none' }} type="button" onClick={() => { this.handleEditBalance(); }}>
                                                <i className="material-icons">&#xe263;</i>
                                                <span>{translate('forms.updateBalance')}</span>
                                            </button>
                                        }

                                        {session.auth([{ type: "Customer", value: "Save" }]) &&
                                            <button type="button" className="btn btn-success waves-effect" disabled={this.state.submitLoading} onClick={this.handleSubmit}>
                                                <i className="material-icons">&#xe161;</i>
                                                <span>{translate('forms.buttonSave')}</span>
                                            </button>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Balance */}
                    <Modal
                        open={this.state.balance.open}
                        closeOnEsc={false}
                        onClose={() => { }}
                        onEntered={() => { this.forceUpdate(); }}
                        closeIconSize={0}
                        center={true}
                        styles={{ modal: { maxWidth: '60%' } }}
                    >
                        <div>
                            <div style={{ width: 9999 }}></div>
                            <div className="row">
                                <div className="col-md-11 formActions">
                                    <h4>{translate('forms.updateBalance')}</h4>
                                </div>
                                <div className="col-md-1 formActions">
                                    <div className="header-dropdown" style={{ float: 'right' }}>
                                        <div className="preloader pl-size-xs" style={{ display: this.state.submitLoading ? 'block' : 'none' }}>
                                            <div className="spinner-layer">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div>

                                                <div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <p>
                                                {translate('customer.customerBalanceAvailable')}{': '}
                                                <b>
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.customer.balanceAvailable)}
                                                </b>
                                            </p>

                                            <div className="form-line">

                                                <NumberFormat prefix="R$ " placeholder={translate('customer.customerBalanceAvailablePlaceHolder')} className="form-control" value={this.state.currentBalanceAvailable} thousandSeparator={true} thousandSeparator={'.'} decimalSeparator={','} onValueChange={(values) => {
                                                    const { formattedValue, value } = values;

                                                    let currentBalanceAvailable = this.state.currentBalanceAvailable;
                                                    currentBalanceAvailable = formattedValue;

                                                    // formattedValue = $2,223
                                                    // value ie, 2223
                                                    this.setState({ currentBalanceAvailable: currentBalanceAvailable })
                                                }} />
                                            </div>

                                            {this.balanceValidator.message('currentBalanceAvailable', this.state.currentBalanceAvailable, 'required', false, { default: translate('customer.customerBalanceAvailableRequired') })}
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <p>
                                                {translate('customer.customerBalanceDueDate')}:
													<b> {this.state.currentBalanceDueDate ? window.Moment(this.state.currentBalanceDueDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}</b>
                                            </p>

                                            <div className="form-line">
                                                <RCDatePicker id="dtBalanceDueDate" name="currentBalanceDueDate" value={this.state.currentBalanceDueDate} onChange={this.handleChange} />
                                            </div>

                                            {this.balanceValidator.message('currentBalanceDueDate', this.state.currentBalanceDueDate, 'required|balanceDueDateLaterThanCurrentDate', false, { default: translate('customer.customerBalanceDueDateRequired'), balanceDueDateLaterThanCurrentDate: translate('customer.customerBalanceDueDateLaterThanEndDate') })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="align-right">
                                <div className="m-t-20">

                                    <button type="button" className="m-r-10 btn btn-default waves-effect" disabled={this.state.submitLoading} onClick={() => { this.handleReturnBalance(); }}>
                                        <i className="material-icons">&#xe5c4;</i>
                                        <span>{translate('forms.buttonReturn')}</span>
                                    </button>

                                    <button type="button" className="btn btn-success waves-effect" disabled={this.state.submitLoading} onClick={() => { this.handleSaveBalance(); }}>
                                        <i className="material-icons">&#xe161;</i>
                                        <span>{translate('forms.updateBalance')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <ToastContainer hideProgressBar />
                </div>
            )

        } else {

            return (
                <div className="preloader pl-size-lg align-center">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>

                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {

    function handleClick() {

        tabToShow.trigger('click');
        closeToast();
    }

    return (

        <div onClick={handleClick}>
            {message}
        </div>
    );
}

export default CustomerPage;