import $ from 'jquery';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { translate } from 'react-internationalization';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from 'simple-react-validator';
import RCSelect from '../components/select';
import * as session from '../components/SessionValidator';
import ModalSupplierSearch from '../components/supplier/modal-supplier-search';
import { submitReportDailyLog } from './helper';
import QueryString from 'query-string';

const moment = window.Moment;

class CustomerSuppliersPage extends React.Component {

    constructor(props) {

        super(props);

        this.id = this.props.match.params.id;
        this.token = session.getToken();
        this.supplierNames = {};

        this.setValidators();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitDelete = this.handleSubmitDelete.bind(this);

        this.addCustomerSupplier = this.addCustomerSupplier.bind(this);
        this.cancelCustomerSupplier = this.cancelCustomerSupplier.bind(this);
        this.resetCustomerSupplier = this.resetCustomerSupplier.bind(this);

        this.handleChangeSupplierSearch = this.handleChangeSupplierSearch.bind(this);
        this.handleReturnSupplierSearch = this.handleReturnSupplierSearch.bind(this);
        this.handleResultSupplierSearch = this.handleResultSupplierSearch.bind(this);

        this.state = {

            customer: {},

            suppliers: [],
            supplierClassification: [],
            classifications: null,

            customerSuppliers: {

                rowIndex: -1,
                customerSupplier: {}
            },

            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            },

            supplierSearchOpen: false,
            supplierLoading: true,
            loading: true,

            query: {},
            tablePage: 0,
            tablePageSize: 10,
            tablePageCount: 0,
            numberOfResults: 0,
            currentPage: 0,
            rowCount: 0,
        };
    }

    async componentDidMount() {

        let _this = this;

        $('body').off('change', '.js-change').on('change', '.js-change', function (event) { _this.handleChange(event); });

        this.getCustomer();
        this.loadClassification();
        this.loadSupplierClassification();        
    }

    async componentDidUpdate() {

        let _this = this;
        $('.js-change').each(function () { _this.setValue(this); });
    }

    loadNextResults(page, pageSize) {

        if (pageSize === undefined) pageSize = this.state.tablePageSize;

        this.setState({ tablePage: page, tablePageSize: pageSize });
        let numberOfResults = this.state.numberOfResults;
        let numberOfRows = pageSize * (page + 1);
        let currentPage = this.state.currentPage;

        if (numberOfResults <= numberOfRows) {
            let nextPage = currentPage + 1;
            this.setState({ currentPage: nextPage });
            this.loadSupplierClassification();
        }

        let tablePageCount = Math.ceil(this.state.rowCount / pageSize);
        this.setState({ tablePageCount: tablePageCount });
    }

    async loadSupplierClassification() {

        let numberOfResults = this.state.numberOfResults;
        let supplierClassification = this.state.supplierClassification;

        this.state.query.customerId = this.id;
        this.state.query.page = this.state.currentPage + 1;
        this.state.query.pageSize = this.state.tablePageSize;
        
        this.setState({ loading: true });

        if (this.id) {

            await fetch(window.$CustomerServiceURL + '/api/customer/ListSupplierClassification/?' + QueryString.stringify(this.state.query),
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();

                })
                .then(res => {

                    if (!res.data) res.data = [];

                    if (res.data && res.data.result) {
                        res.data.result = res.data.result.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                        supplierClassification = supplierClassification.concat(res.data.result);

                        numberOfResults = numberOfResults + (res.data.result == null ? 0 : res.data.result.length);

                        this.setState({
                            supplierClassification: supplierClassification,
                            numberOfResults: numberOfResults, currentPage: res.data.currentPage,
                            tablePageCount: res.data.pageCount, rowCount: res.data.rowCount
                        });

                        this.setState({ loading: false });
                        this.forceUpdate();
                    }
                });

        }
    }

    async getCustomer() {

        if (this.id) {
            await fetch(window.$CustomerServiceURL + '/api/Customer/GetSimpleById/' + this.id,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];
                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];
                        return res;
                    }

                    return res.json();
                })
                .then(res => {

                    if (res.data) {

                        this.setState({ customer: res.data });

                        this.setState(this.state);
                        this.forceUpdate();
                    }
                });
        }
    }

    async getCustomerSupplier(supplierId) {

        if (this.id) {

            let query = {};

            query.customerId = this.id;
            query.supplierId = supplierId;

            await fetch(window.$CustomerServiceURL + '/api/Customer/GetSimpleCustomerSupplierClassification/?' + QueryString.stringify(query),
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];
                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];
                        return res;
                    }

                    return res.json();
                })
                .then(res => {

                    if (res.data) {

                        this.resetCustomerSupplier();

                        this.state.customerSuppliers.rowIndex = 0;//para o botão ficar como "Atualizar Fornecedor"

                        this.state.customerSuppliers.customerSupplier = res.data;

                        this.setState(this.state);
                        this.forceUpdate();                        
                    }
                });
        }
    }

    loadClassification() {

        fetch(window.$CustomerServiceURL + '/api/Customer/ListClassification/' + this.id,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];
                    return res;
                }

                return res.json();
            })
            .then(res => {

                if (res.data)
                    res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                this.setState({ classifications: res.data });
                this.forceUpdate();
            });
    }

    handleChangeSupplierSearch(supplierId) {

        this.state.customerSuppliers.customerSupplier.supplierId = supplierId;
        this.state.supplierSearchOpen = false;
        this.forceUpdate();

        this.getCustomerSupplier(supplierId);
    }

    handleReturnSupplierSearch() {

        this.state.supplierSearchOpen = false;
        this.forceUpdate();
    }

    handleResultSupplierSearch(suppliers) {

        //copy for names in table
        this.supplierNames = JSON.parse(JSON.stringify(suppliers));

        this.setState({ suppliers: suppliers, supplierLoading: false });
        this.forceUpdate();
    }

    async handleChange(event) {

        session.validateSession();

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;

            }

        });

        this.setState(this.state);

    }

    setValue(element) {

        let keys = element.name.split('.'), property = this.state;

        keys.forEach(key => {

            property = property[key];
            if (Array.isArray(property)) property = property[element.dataset.index];

        });

        $(element).val(property);
    }

    handleSubmit() {

        var customerSupplier = JSON.parse(JSON.stringify(this.state.customerSuppliers.customerSupplier));
        customerSupplier.customerId = this.id;

        var itemCustomerSupplier = $.extend(true, {}, customerSupplier),
            status = 0;

        let reportDailyLog = this.state.reportDailyLog;

        this.setState({ submitLoading: true });

        fetch(window.$CustomerServiceURL + '/api/Customer/UpdateCustomerSupplierClassification',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                body: JSON.stringify(itemCustomerSupplier),
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                status = res.status;
                this.setState({ submitLoading: false });

                switch (status) {

                    case 401:
                        session.sessionExpired();
                        res = [];

                        return res;

                        break;

                    case 403:
                        session.accessDenied();
                        res = [];

                        return res;

                        break;

                    default:
                        return res.json();
                }

            }).then(res => {

                switch (status) {

                    case 200:
                        
                        reportDailyLog.action = 20;//20 = UpdateCustomerSupplier
                        reportDailyLog.objectAfter = JSON.stringify(itemCustomerSupplier);
                        submitReportDailyLog(reportDailyLog);

                        ////////////////////////

                        this.addCustomerSupplierGrid(res.data.id);
                                                
                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });
    }

    addCustomerSupplierGrid(id) {

        let supplierId = parseInt(this.state.customerSuppliers.customerSupplier.supplierId);
        if (isNaN(supplierId) || supplierId <= 0) return;

        var suppliers = this.state.suppliers;
        var supplier = suppliers.splice(suppliers.findIndex(item => { return item.id === supplierId; }), 1)[0];

        this.state.suppliers = suppliers;

        ////////////////////////

        var customerSupplier = JSON.parse(JSON.stringify(this.state.customerSuppliers.customerSupplier));
        customerSupplier.id = id;
        customerSupplier.supplierName = supplier.name;

        if (this.state.customerSuppliers.rowIndex < 0) {

            this.state.supplierClassification.push(customerSupplier);

        } else {

            this.state.supplierClassification[this.state.customerSuppliers.rowIndex] = customerSupplier;
        }

        this.resetCustomerSupplier();
    }

    async handleSubmitDelete(customerSupplier, rowIndex) {

        let reportDailyLog = this.state.reportDailyLog;
        var status = 0;

        this.setState({ submitLoading: true });

        await fetch(window.$CustomerServiceURL + '/api/Customer/DeleteCustomerSupplier/' + customerSupplier.id,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                status = res.status;
                this.setState({ submitLoading: false });

                switch (status) {

                    case 401:
                        session.sessionExpired();
                        res = [];
                        return res;

                        break;

                    case 403:
                        session.accessDenied();
                        res = [];
                        return res;

                        break;

                    default:
                        return res.json();
                }

            }).then(res => {

                switch (status) {

                    case 200:

                        reportDailyLog.action = 20;//20 = UpdateCustomerSupplier
                        reportDailyLog.objectAfter = JSON.stringify(customerSupplier);
                        submitReportDailyLog(reportDailyLog);

                        ////////////////////////1

                        let customerSupplierItem = JSON.parse(JSON.stringify(this.state.supplierClassification[rowIndex]));

                        ////////////////////////1

                        this.state.supplierClassification.splice(rowIndex, 1);

                        ////////////////////////2

                        let supplierId = parseInt(customerSupplierItem.supplierId);
                        if (isNaN(supplierId) || supplierId <= 0) return;

                        var suppliers = JSON.parse(JSON.stringify(this.supplierNames));

                        var supplier = suppliers.splice(suppliers.findIndex(item => { return item.id === supplierId; }), 1)[0];

                        this.state.suppliers.push(supplier);
                        this.state.suppliers.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                        ////////////////////////2

                        if (this.state.customerSuppliers.rowIndex === rowIndex) {
                            this.resetCustomerSupplier();

                        } else {
                            this.setState(this.state);
                        }

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });
    }

    setValidators() {

        this.validator = new SimpleReactValidator({});
    }

    addCustomerSupplier() {

        if (!this.validator.allValid()) {

            this.validator.showMessages();
            this.forceUpdate();

            return;
        }

        //salvar na base de dados
        this.handleSubmit();
    }

    editCustomerSupplier(rowIndex) {

        if (this.state.customerSuppliers.rowIndex == -1) {

            this.state.reportDailyLog.objectBefore = JSON.stringify(this.state.supplierClassification[rowIndex]);

            this.state.customerSuppliers.customerSupplier = JSON.parse(JSON.stringify(this.state.supplierClassification[rowIndex]));

            this.state.customerSuppliers.rowIndex = rowIndex;

            ///////////////////////

            let supplierId = parseInt(this.state.customerSuppliers.customerSupplier.supplierId);
            if (isNaN(supplierId) || supplierId <= 0) return;

            var suppliers = JSON.parse(JSON.stringify(this.supplierNames));

            var supplier = suppliers.splice(suppliers.findIndex(item => { return item.id === supplierId; }), 1)[0];

            supplier = !suppliers.find(x => { return x.id === supplierId; }) ? Object.create({ id: supplierId, name: this.state.customerSuppliers.customerSupplier.supplierName }) : supplier;

            this.state.suppliers.push(supplier);

            ////////////////////////

            this.setState(this.state);
        }
    }

    deleteCustomerSupplier(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        let customerSupplier = JSON.parse(JSON.stringify(this.state.supplierClassification[rowIndex]));

                        this.handleSubmitDelete(customerSupplier, rowIndex);  
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('customer.customerSupplierDeleteTitle'),
            title: translate('customer.customerSupplierDeleteQuestion')
        });
    }

    cancelCustomerSupplier() {

        ////////////////////////

        let supplierId = parseInt(this.state.customerSuppliers.customerSupplier.supplierId);
        if (isNaN(supplierId) || supplierId <= 0) return;

        var suppliers = this.state.suppliers;
        var supplier = suppliers.splice(suppliers.findIndex(item => { return item.id === supplierId; }), 1)[0];

        this.state.suppliers = suppliers;

        ////////////////////////

        this.resetCustomerSupplier();
    }

    resetCustomerSupplier() {

        this.state.customerSuppliers = {

            rowIndex: -1,
            customerSupplier: {}
        };

        if (this.validator !== undefined) {
            this.validator.hideMessages();
        }

        this.setState(this.state);
    }

    render() {

        var fetched = this.state != null && this.state.customer != null && this.state.classifications != null;

        if (fetched) {

            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    <ul className="nav nav-tabs tab-nav-right" role="tablist">
                                        <li className="active" role="presentation">
                                            <a href="#supplierPanel" data-toggle="tab">{translate('customer.customerSupplierTitle')} - {this.state.customer.name}</a>
                                        </li>
                                    </ul>
                                </h2>

                                <div className="header-dropdown">
                                    <div className="preloader pl-size-xs" style={{ display: this.state.submitLoading ? 'block' : 'none' }}>
                                        <div className="spinner-layer">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>

                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <div className="tab-content">

                                    <div id="supplierPanel" className="tab-pane fade in active" role="tabpanel">
                                        {
                                            (session.isAdmin() || session.isOperator() || session.isCustomer()) && session.auth([{ type: "Customer", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('customer.customerSupplierTitleDescription')}</small>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-5">
                                                        <div className="m-b-20">
                                                            <label className="validationRequired" htmlFor="cmbSupplier">{translate('customer.customerSupplier')}</label>

                                                            <RCSelect
                                                                id="cmbSupplier"
                                                                name="customerSuppliers.customerSupplier.supplierId"
                                                                hasEmptyOption={true}
                                                                isDisabled={this.state.customerSuppliers.rowIndex >= 0}
                                                                isInt={true}
                                                                options={this.state.suppliers}
                                                                optionValue="id"
                                                                optionLabel="name"
                                                                placeholder={translate('customer.customerSupplierPlaceHolder')}
                                                                onChange={event => { this.handleChange(event); }}
                                                                value={this.state.customerSuppliers.customerSupplier.supplierId}
                                                            />

                                                            {this.validator.message('customerSuppliers.customerSupplier.supplierId', this.state.customerSuppliers.customerSupplier.supplierId, 'gt: 0', false, { default: translate('customer.customerSupplierRequired') })}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-1 m-t-20 p-l-0">
                                                        <button type="button" className="btn btn-default waves-effect" onClick={() => {
                                                            this.state.supplierSearchOpen = true;
                                                            this.forceUpdate();
                                                        }}>
                                                            <i className="material-icons">&#xe8b6;</i>
                                                        </button>

                                                        <ModalSupplierSearch
                                                            customerId={null}
                                                            SearchOpen={this.state.supplierSearchOpen}
                                                            handleChange={event => { this.handleChangeSupplierSearch(event); }}
                                                            handleResult={event => { this.handleResultSupplierSearch(event); }}
                                                            handleReturn={() => { this.handleReturnSupplierSearch(); }}
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="m-b-20">
                                                            <label htmlFor="cmbClassifications" className="validationRequired">{translate('customer.customerSupplierClassification')}</label>

                                                            <RCSelect
                                                                id="cmbClassifications"
                                                                name="customerSuppliers.customerSupplier.customerClassificationId"
                                                                hasEmptyOption={true}
                                                                isInt={true}
                                                                options={this.state.classifications}
                                                                optionValue="id"
                                                                optionLabel="displayName"
                                                                placeholder={translate('customer.customerSupplierClassificationPlaceHolder')}
                                                                onChange={event => { this.handleChange(event); }}
                                                                value={this.state.customerSuppliers.customerSupplier.customerClassificationId}
                                                            />

                                                            {this.validator.message('cmbClassifications', this.state.customerSuppliers.customerSupplier.customerClassificationId, 'gt: 0', false, { default: translate('customer.customerSupplierClassificationRequired') })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <button type="button" className="btn m-r-10" onClick={this.cancelCustomerSupplier} style={{ display: this.state.customerSuppliers.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                            <i className="material-icons">&#xe5c4;</i>
                                                            <span>{translate('forms.buttonCancel')}</span>
                                                        </button>

                                                        <button type="button" className="btn btn-primary" onClick={this.addCustomerSupplier}>
                                                            <i className="material-icons">&#xe145;</i>
                                                            <span>{translate(this.state.customerSuppliers.rowIndex < 0 ? 'customer.customerSupplierAddNew' : 'customer.customerSupplierUpdate')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <ReactTable
                                            data={this.state.supplierClassification}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('customer.customerSupplier'),
                                                            id: "supplier",
                                                            accessor: "supplierName"
                                                        },
                                                        {
                                                            Header: translate('customer.customerClassificationsTitle'),
                                                            id: "classification",
                                                            accessor: data => { return this.state.classifications.find(x => { return x.id === parseInt(data.customerClassificationId); }).displayName; }
                                                        },
                                                        session.auth([{ type: "Customer", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    {
                                                                        (session.isAdmin() || session.isOperator()) &&
                                                                        <button disabled={this.state.supplierLoading || this.state.customerSuppliers.rowIndex >= 0} className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteCustomerSupplier(row.index) }}>
                                                                            <i className="material-icons">&#xe872;</i>
                                                                        </button>
                                                                    }

                                                                    {
                                                                        (session.isAdmin() || session.isOperator() || session.isCustomer()) &&
                                                                        <button disabled={this.state.supplierLoading || this.state.customerSuppliers.rowIndex >= 0} type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editCustomerSupplier(row.index) }}>
                                                                            <i className="material-icons">&#xe3c9;</i>
                                                                        </button>
                                                                    }

                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            loading={this.state.loading}

                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}

                                            pages={this.state.tablePageCount}
                                            page={this.state.tablePage}
                                            pageSize={this.state.tablePageSize}
                                            onPageSizeChange={(pageSize, page) => this.loadNextResults(page, pageSize)}
                                            onPageChange={(page) => this.loadNextResults(page)}
                                        />
                                    </div>

                                    <div id="tradeAgreementPanel" className="tab-pane fade in" role="tabpanel">
                                        <div>
                                            <div className="m-b-40">
                                                <small>Lista de Fornecedores</small>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row clearfix">
                                    <div className="col-md-12 formActions">
                                        <Link to="/customers" className="btn btn-default waves-effect">
                                            <i className="material-icons">&#xe5c4;</i>
                                            <span>{translate('forms.buttonReturn')}</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ToastContainer hideProgressBar />
                </div>
            )

        } else {

            return (
                <div className="preloader pl-size-lg align-center">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>

                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {

    function handleClick() {

        tabToShow.trigger('click');
        closeToast();
    }

    return (

        <div onClick={handleClick}>
            {message}
        </div>
    );
}

export default CustomerSuppliersPage;