import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { ToastContainer, toast } from 'react-toastify';
import { translate } from 'react-internationalization';
import ReactTable from "react-table";
import SimpleReactValidator from 'simple-react-validator'
import $ from 'jquery';
import RCSelect from '../components/select';
import * as session from '../components/SessionValidator';
import { cpf } from '../components/cpf-cnpj-validator';
import { cnpj } from '../components/cpf-cnpj-validator';
import { cpf_cnpj_Mask } from '../components/mask'
import { submitReportDailyLog } from './helper';
import * as enums from '../components/enums/supplier';
import * as enumsBank from '../components/enums/bank';

const moment = window.Moment;

class SupplierPage extends React.Component {

    constructor(props) {

        super(props);

        this.id = this.props.match.params.id;
        this.token = session.getToken();

        this.setValidators();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOnlyInt = this.handleChangeOnlyInt.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSaveRate = this.handleSaveRate.bind(this);

        this.handlechangeMask = this.handlechangeMask.bind(this)

        this.addAddress = this.addAddress.bind(this);
        this.resetAddress = this.resetAddress.bind(this);
        this.addContact = this.addContact.bind(this);
        this.resetContact = this.resetContact.bind(this);
        this.addBankAccount = this.addBankAccount.bind(this);
        this.resetBankAccount = this.resetBankAccount.bind(this);

        this.state = {

            supplier: {
                cnpj: ''
            },

            countries: null,
            addressStates: null,

            contacts: {

                rowIndex: -1,
                contact: {
                    name: '',
                    position: '',
                    phoneNumber: '',
                    emails: '',
                    notes: '',
                    isActive: true,
                    isMaster: true
                }
            },

            bankAccounts: {

                rowIndex: -1,
                bankAccount: {
                    name: '',
                    BankNumber: '',
                    agencyNumber: '',
                    accountNumber: '',
                    notes: '',
                    isActive: true
                }
            },

            addresses: {

                rowIndex: -1,
                address: {

                    name: '',
                    legalName: '',
                    document: '',
                    documentType: 0,
                    country: {},
                    state: {},
                    city: '',
                    district: '',
                    street: '',
                    number: '',
                    complement: '',
                    zipCode: '',
                    isMaster: true,
                    phoneNumber: '',
                    email: '',
                    stateRegistration: ''
                }
            },

            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            },

            rate: {
                open: false,
                customerId: 0,
                customerName: '',
                currentRate: 0,
                customerClassification: {}
            },

            customerSuppliers: [],
        };

    }

    handlechangeMask(e) {
        e.target.value = cpf_cnpj_Mask(e.target.value);
    }

    async componentDidMount() {

        let _this = this;
        let reportDailyLog = this.state.reportDailyLog;

        $('body').on('change', '.js-change', function (event) { _this.handleChange(event); });

        fetch(window.$CustomerServiceURL + '/api/Country/List',
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {

                res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                this.setState({ countries: res.data });
                this.forceUpdate();
            });

        if (this.id) {

            await fetch(window.$SupplierServiceURL + '/api/supplier/' + this.id,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();

                })
                .then(res => {

                    reportDailyLog.objectBefore = JSON.stringify(res.data);

                    this.setState({ supplier: res.data, reportDailyLog: reportDailyLog });
                    this.forceUpdate();
                });

        } else {

            let supplier = {

                isActive: true,
                address: [],
                contact: [],
                bankAccount: []
            }

            this.setState({ supplier: supplier });
            this.forceUpdate();
        }
    }

    async componentDidUpdate() {

        let _this = this;

        $('.js-change').each(function () { _this.setValue(this); });
        $('.js-phone-number').inputmask({ greedy: false, mask: '(99) 9999[9]-9999', showMaskOnHover: false });
        $('.js-integer').inputmask({ greedy: false, mask: '9', repeat: '*', showMaskOnHover: false });
        $('.js-decimal').inputmask('decimal', { digits: 4, digitsOptional: false, max: 999999999, placeholder: '0.00', rightAlign: false, showMaskOnHover: false });
        $('.js-letters').inputmask({ greedy: false, mask: 'a', repeat: '*', showMaskOnHover: false });
        $('.js-zipcode').inputmask({ greedy: false, mask: '99999-999', showMaskOnHover: false });
    }

    async handleChange(event) {

        session.validateSession();

        if (event.target.id === "cmbAddressCountry") {

            this.state.addresses.address.state = {};
            this.state.addresses.address.country = this.state.countries.find(item => { return item.id === event.target.value; });;
            this.state.addressStates = null;

            if (event.target.value > 0) this.loadStates('addressStates', 'addressStatesLoading', event.target.value);
        }

        if (event.target.id === "cmbAddressState") {

            this.state.addresses.address.state = this.state.addressStates.find(item => { return item.id === event.target.value; });;
        }

        if (event.target.id === "cmbCustomer") {

            var customerSupplier = this.state.customerSuppliers.find(item => { return item.customerId === event.target.value; });

            if (customerSupplier) {
                this.state.rate.customerClassification = customerSupplier.customerClassification;
                this.state.rate.currentRate = customerSupplier.currentRate;
            }
        }

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    handleChangeOnlyInt(event) {

        let value = event.target.value;
        value = value.match(/\d+/g) == null ? '' : value.match(/\d+/g).join([]);
        event.target.value = value;

        this.handleChange(event);
    }

    handleTypingEmail(e) {
        e.target.value = e.target.value.toLowerCase()
    }

    setValue(element) {

        let keys = element.name.split('.'), property = this.state;

        keys.forEach(key => {

            property = property[key];
            if (Array.isArray(property)) property = property[element.dataset.index];

        });

        $(element).val(property);
    }

    handleSubmit() {

        if (!this.validator.allValid()) {

            this.validator.showMessages();
            this.forceUpdate();

            return;
        }

        if (this.state.supplier.address.length <= 0) {
            toast.error(<ToastRedirect message={translate('supplier.supplierAddressesRequired')} tabToShow={$('#addresses-tab-link')} />);
            $('#addresses-tab-link').addClass('invalid')
            return;
        }

        if (this.state.supplier.bankAccount.length <= 0) {
            toast.error(<ToastRedirect message={translate('supplier.supplierBankAccountsRequired')} tabToShow={$('#bankAccounts-tab-link')} />);
            $('#bankAccounts-tab-link').addClass('invalid')
            return;
        }

        var supplier = $.extend(true, {}, this.state.supplier),
            method = this.id ? 'Update' : 'Create',
            status = 0;

        let reportDailyLog = this.state.reportDailyLog;

        this.setState({ submitLoading: true });

        fetch(window.$SupplierServiceURL + '/api/Supplier/' + method,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                body: JSON.stringify(supplier),
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                status = res.status;
                this.setState({ submitLoading: false });

                switch (status) {

                    case 401:
                        session.sessionExpired();
                        res = [];
                        return res;

                        break;

                    case 403:
                        session.accessDenied();
                        res = [];
                        return res;

                        break;

                    default:
                        return res.json();
                }

            }).then(res => {

                switch (status) {

                    case 200:
                        this.props.history.push('/suppliers');
                        toast.success(translate(!this.id ? 'supplier.supplierCreated' : 'supplier.supplierUpdated'));

                        reportDailyLog.action = this.id ? 12 : 11;//11 = createSupplier 12 = updateSupplier
                        reportDailyLog.objectAfter = JSON.stringify(supplier);
                        submitReportDailyLog(reportDailyLog);

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });
    }

    setValidators() {

        this.validator = new SimpleReactValidator({

            typePerson: {

                rule: (value) => {

                    return ['', null, undefined].indexOf(value) < 0;
                }
            },
        });

        this.rateValidator = new SimpleReactValidator({

            rate: {

                rule: (value) => {

                    let startRate = parseFloat(this.state.rate.customerClassification.startRate);
                    let endRate = parseFloat(this.state.rate.customerClassification.endRate);

                    return value >= startRate && value <= endRate;
                }
            },
        });

        this.contactValidator = new SimpleReactValidator({

            emails: {

                rule: function (val) {

                    if (val === '') return true;
                    var emails = val.replace(new RegExp(',', 'g'), ';').split(";");
                    for (var i = 0, len = emails.length; i < len; i++) if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emails[i].trim())) return false;

                    return true;
                }
            }
        });

        this.bankAccountValidator = new SimpleReactValidator({

            bankNumber: {

                rule: (value) => {

                    return ['', null, undefined].indexOf(value) < 0;
                }
            },
        });

        this.addressValidator = new SimpleReactValidator({

            state: {

                rule: value => {

                    return !this.state.addressStates || this.state.addressStates.length <= 0 || parseInt(value) > 0;
                }
            },

            documentType: {

                rule: (value) => {

                    return ['', null, undefined].indexOf(value) < 0;
                }
            },

            document: {

                rule: (value) => {

                    let documentType = this.state.addresses.address.documentType;

                    if (documentType == 0)//cnpj
                    {
                        return cnpj.isValid(value);
                    }
                    else if (documentType == 1) {//cpf
                        return cpf.isValid(value);
                    }
                    else {
                        return false;
                    }
                }
            },

            documentAdded: {

                rule: (value) => {

                    var item = this.state.supplier.address ? this.state.supplier.address.find(item => { return item.document.replace(".", "").replace(".", "").replace("-", "").replace("/", "") === value.replace(".", "").replace(".", "").replace("-", "").replace("/", ""); }) : null;

                    return !item || (item && this.state.addresses.rowIndex >= 0);
                }
            },

            email: {

                rule: function (val) {

                    if (val === '')
                        return true;

                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val.trim()))
                        return false;

                    return true;
                }
            }
        });
    }

    addAddress() {

        if (!this.addressValidator.allValid()) {

            this.addressValidator.showMessages();
            this.forceUpdate();
            return;
        }

        var address = JSON.parse(JSON.stringify(this.state.addresses.address));

        if (address.isMaster) {

            //desabilita todos
            for (var i = 0; i < this.state.supplier.address.length; i++) {

                this.state.supplier.address[i].isMaster = false;
            }
        }

        if (this.state.addresses.rowIndex < 0) {

            this.state.supplier.address.push(address);

        } else {

            this.state.supplier.address[this.state.addresses.rowIndex] = address;
        }

        this.resetAddress();
    }

    editAddress(rowIndex) {

        this.state.addresses.address = JSON.parse(JSON.stringify(this.state.supplier.address[rowIndex]));
        this.state.addresses.rowIndex = rowIndex;

        if (this.state.supplier.address[rowIndex].country.id > 0) this.loadStates('addressStates', 'addressStatesLoading', this.state.supplier.address[rowIndex].country.id);
    }

    deleteAddress(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        var items = this.state.supplier.address;
                        items.splice(rowIndex, 1);
                        this.state.supplier.address = items;

                        if (this.state.addresses.rowIndex === rowIndex) {
                            this.resetAddress();

                        } else {
                            this.setState(this.state);
                        }
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('supplier.supplierAddressDeleteTitle'),
            title: translate('supplier.supplierAddressDeleteQuestion')

        });

    }

    resetAddress() {

        this.state.addressStates = null;

        this.state.addresses = {

            rowIndex: -1,
            address: {

                name: '',
                legalName: '',
                document: '',
                documentType: 0,
                country: {},
                state: {},
                city: '',
                district: '',
                street: '',
                number: '',
                complement: '',
                zipCode: '',
                isMaster: true,
                phoneNumber: '',
                email: '',
                stateRegistration: ''
            }
        };

        if (this.addressValidator !== undefined) {
            this.addressValidator.hideMessages();
        }

        this.setState(this.state);
    }

    addBankAccount() {

        if (!this.bankAccountValidator.allValid()) {

            this.bankAccountValidator.showMessages();
            this.forceUpdate();

            return;
        }

        var bankAccount = JSON.parse(JSON.stringify(this.state.bankAccounts.bankAccount));

        if (bankAccount.isMaster) {

            //desabilita todos
            for (var i = 0; i < this.state.supplier.bankAccount.length; i++) {

                this.state.supplier.bankAccount[i].isMaster = false;
            }
        }

        if (this.state.bankAccounts.rowIndex < 0) {

            this.state.supplier.bankAccount.push(bankAccount);

        } else {

            this.state.supplier.bankAccount[this.state.bankAccounts.rowIndex] = bankAccount;
        }

        this.resetBankAccount();
    }

    editBankAccount(rowIndex) {

        this.state.bankAccounts.bankAccount = JSON.parse(JSON.stringify(this.state.supplier.bankAccount[rowIndex]));
        this.state.bankAccounts.rowIndex = rowIndex;

        this.setState(this.state);
    }

    deleteBankAccount(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        var items = this.state.supplier.bankAccount;
                        items.splice(rowIndex, 1);
                        this.state.supplier.bankAccount = items;

                        if (this.state.bankAccounts.rowIndex === rowIndex) { this.resetBankAccount(); } else { this.setState(this.state); }
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('supplier.supplierSetupAccountDeleteTitle'),
            title: translate('supplier.supplierSetupAccountDeleteQuestion')
        });

    }

    resetBankAccount() {
        this.state.bankAccounts = {

            rowIndex: -1,
            bankAccount: {
                name: '',
                BankNumber: '',
                agencyNumber: '',
                accountNumber: '',
                notes: '',
                isActive: true,
                isMaster: true
            }
        };

        if (this.bankAccountValidator !== undefined) {
            this.bankAccountValidator.hideMessages();
        }

        this.setState(this.state);
    }

    addContact() {

        if (!this.contactValidator.allValid()) {

            this.contactValidator.showMessages();
            this.forceUpdate();

            return;
        }

        var contact = JSON.parse(JSON.stringify(this.state.contacts.contact));

        if (contact.isMaster) {

            //desabilita todos
            for (var i = 0; i < this.state.supplier.contact.length; i++) {

                this.state.supplier.contact[i].isMaster = false;
            }
        }

        if (this.state.contacts.rowIndex < 0) {

            this.state.supplier.contact.push(contact);

        } else {

            this.state.supplier.contact[this.state.contacts.rowIndex] = contact;
        }

        this.resetContact();
    }

    editContact(rowIndex) {

        this.state.contacts.contact = JSON.parse(JSON.stringify(this.state.supplier.contact[rowIndex]));
        this.state.contacts.rowIndex = rowIndex;

        this.setState(this.state);
    }

    deleteContact(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        var items = this.state.supplier.contact;
                        items.splice(rowIndex, 1);
                        this.state.supplier.contact = items;

                        if (this.state.contacts.rowIndex === rowIndex) { this.resetContact(); } else { this.setState(this.state); }
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('supplier.supplierContactDeleteTitle'),
            title: translate('supplier.supplierContactDeleteQuestion')
        });

    }

    resetContact() {

        this.state.contacts = {

            rowIndex: -1,
            contact: {
                name: '',
                position: '',
                phoneNumber: '',
                emails: '',
                notes: '',
                isActive: true,
                isMaster: true
            }
        };

        if (this.contactValidator !== undefined) {
            this.contactValidator.hideMessages();
        }

        this.setState(this.state);
    }

    async loadStates(states, statesLoading, countryId) {

        this.state[statesLoading] = true;
        this.setState(this.state);

        fetch(window.$CustomerServiceURL + '/api/State/List?countryId=' + countryId,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {

                this.state[states] = res.data;
                this.state[statesLoading] = false;

                this.setState(this.state);

                this.forceUpdate();
            });
    }

    handleEditRate() {

        this.state.rate.open = true;

        this.loadCustomerSuppliers();

        this.rateValidator.hideMessages();
        this.forceUpdate();
    }

    async handleSaveRate() {

        let valid = this.rateValidator.allValid();

        if (valid) {

            var customerSupplier = this.state.customerSuppliers.find(item => { return item.customerId === this.state.rate.customerId; });

            var newCustomerSupplier = JSON.parse(JSON.stringify(customerSupplier));
            newCustomerSupplier.currentRate = this.state.rate.currentRate;

            var itemCustomerSupplier = $.extend(true, {}, newCustomerSupplier),
                status = 0;

            let reportDailyLog = {
                startDate: moment().format(),
                objectAfter: JSON.stringify(newCustomerSupplier),
                objectBefore: JSON.stringify(customerSupplier)
            };

            this.setState({ submitLoading: true });

            await fetch(window.$CustomerServiceURL + '/api/Customer/UpdateSupplierRate',
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    body: JSON.stringify(itemCustomerSupplier),
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    status = res.status;
                    this.setState({ submitLoading: false });

                    switch (status) {

                        case 401:
                            session.sessionExpired();
                            res = [];
                            return res;
                            break;

                        case 403:
                            session.accessDenied();
                            res = [];
                            return res;

                            break;

                        default:
                            return res.json();
                    }

                }).then(res => {

                    switch (status) {

                        case 200:

                            reportDailyLog.action = 23;//23 = updateSupplierRate
                            submitReportDailyLog(reportDailyLog);

                            this.state.rate.open = false;
                            this.forceUpdate();

                            break;

                        case 400:
                        case 409:
                            var messages = res.data;
                            messages.forEach(ex => toast.warn(ex.message));
                            break;

                        case 500:
                            toast.error('error');
                            break;
                    }

                }).catch(err => {
                    console.error(err);
                });

        } else {

            this.rateValidator.showMessages();
            this.forceUpdate();
        }
    }

    handleReturnRate() {

        this.state.rate.open = false;

        this.forceUpdate();
    }

    async loadCustomerSuppliers() {

        await fetch(window.$CustomerServiceURL + '/api/Customer/ListCustomerSupplier/' + this.id,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();
            })
            .then(res => {

                if (res.data) {
                    for (var i = 0; i < res.data.length; i++) {

                        res.data[i].customerName = res.data[i].customer.name;
                    }

                    res.data = res.data.sort(function (a, b) { return (a.customerName < b.customerName) ? -1 : (a.customerName > b.customerName) ? 1 : 0; });
                }

                this.setState({ customerSuppliers: res.data });
                this.forceUpdate();
            });
    }

    render() {

        var fetched = this.state != null && this.state.supplier != null && this.state.countries != null;

        if (fetched) {

            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    <ul className="nav nav-tabs tab-nav-right" role="tablist">
                                        <li className="active" role="presentation">
                                            <a href="#supplierPanel" data-toggle="tab">{translate('supplier.supplierTitle')}</a>
                                        </li>

                                        <li role="presentation">
                                            <a id="addresses-tab-link" href="#addressPanel" data-toggle="tab">{translate('supplier.supplierAddressNameTitle')}</a>
                                        </li>

                                        <li role="presentation">
                                            <a href="#contactsPanel" data-toggle="tab">{translate('supplier.supplierContactsTitle')}</a>
                                        </li>

                                        <li role="presentation">
                                            <a id="bankAccounts-tab-link" href="#bankAccountsPanel" data-toggle="tab">{translate('supplier.supplierSetupAccountTitle')}</a>
                                        </li>
                                    </ul>
                                </h2>

                                <div className="header-dropdown">
                                    <div className="preloader pl-size-xs" style={{ display: this.state.submitLoading ? 'block' : 'none' }}>
                                        <div className="spinner-layer">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>

                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <div className="tab-content">
                                    <div id="supplierPanel" className="tab-pane fade in active" role="tabpanel">
                                        <div>
                                            <div className="m-b-40">
                                                <small>{translate('supplier.supplierTitleDescription')}</small>
                                            </div>

                                            <div className="row clearfix">
                                                <div className="col-md-5">
                                                    <div className="input-group">
                                                        <label className="validationRequired" htmlFor="txtName">{translate('supplier.supplierName')}</label>

                                                        <div className="form-line">
                                                            <input id="txtName" disabled={!session.auth([{ type: "Supplier", value: "Save" }])} className="form-control js-change" name="supplier.name" placeholder={translate('supplier.supplierNamePlaceHolder')} type="text" />
                                                        </div>

                                                        {this.validator.message('supplier.name', this.state.supplier.name, 'required', 'validationMessage--error', { default: translate('supplier.supplierNameRequired') })}
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="m-b-20">
                                                        <label htmlFor="cmbTypePerson" className="validationRequired">
                                                            {translate('supplier.supplierTypePerson')}
                                                        </label>

                                                        <RCSelect
                                                            id="cmbTypePerson"
                                                            name="supplier.type"
                                                            hasEmptyOption={true}
                                                            isInt={true}
                                                            options={enums.getTypePerson()}
                                                            optionValue="index"
                                                            optionLabel="text"
                                                            placeholder={translate('supplier.supplierTypePersonPlaceHolder')}
                                                            onChange={event => { this.handleChange(event); }}
                                                            value={this.state.supplier.type}
                                                            isDisabled={!session.auth([{ type: "Supplier", value: "Save" }])}
                                                        />

                                                        {this.validator.message('cmbTypePerson', this.state.supplier.type, 'required|typePerson', false, { default: translate('supplier.supplierTypePersonRequired') })}
                                                    </div>
                                                </div>

                                                {(session.isAdmin() || session.isOperator()) &&
                                                    <div className="col-md-2">
                                                        <div className="m-t-30">
                                                            <input id="chkIsActive" name="supplier.isActive" type="checkbox" checked={this.state.supplier.isActive} onChange={this.handleChange} />
                                                            <label htmlFor="chkIsActive">{translate('supplier.supplierIsActive')}</label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="row clearfix">
                                                <div className="col-md-12">
                                                    <div className="input-group">
                                                        <label htmlFor="txtNotes">{translate('supplier.supplierNotes')}</label>

                                                        <div className="form-line">
                                                            <textarea id="txtNotes" disabled={!session.auth([{ type: "Supplier", value: "Save" }])} className="form-control js-change" name="supplier.notes" placeholder={translate('supplier.supplierNotesPlaceHolder')} rows="4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div id="addressPanel" className="tab-pane fade in" role="tabpanel">
                                        {
                                            session.auth([{ type: "Supplier", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('supplier.supplierAddressTitleDescription')}</small>
                                                    </div>
                                                </div>

                                                <div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-10">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtAddressName">{translate('supplier.supplierAddressName')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtAddressName" name="addresses.address.name" className="form-control js-change" placeholder={translate('supplier.supplierAddressNamePlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.name', this.state.addresses.address.name, 'required', false, { default: translate('supplier.supplierAddressNameRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="m-t-30">
                                                                <input id="chkIsMaster" name="addresses.address.isMaster" type="checkbox" checked={this.state.addresses.address.isMaster} onChange={this.handleChange} />
                                                                <label htmlFor="chkIsMaster">{translate('supplier.supplierAddressIsMaster')}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row clearfix">

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtLegalName" className="validationRequired"> {translate('supplier.supplierLegalName')} </label>

                                                                <div className="form-line">
                                                                    <input id="txtLegalName" name="addresses.address.legalName" className="form-control js-change" autoComplete="off" maxLength='100' placeholder={translate('supplier.supplierLegalNamePlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.legalName', this.state.addresses.address.legalName, 'required', false, { default: translate('supplier.supplierLegalNameRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="m-b-20">
                                                                <label htmlFor="cmbDocumentType" className="validationRequired">
                                                                    {translate('supplier.supplierDocumentType')}
                                                                </label>

                                                                <RCSelect
                                                                    id="cmbDocumentType"
                                                                    name="addresses.address.documentType"
                                                                    hasEmptyOption={true}
                                                                    isInt={true}
                                                                    options={enums.getDocumentType()}
                                                                    optionValue="index"
                                                                    optionLabel="text"
                                                                    placeholder={translate('supplier.supplierDocumentTypePlaceHolder')}
                                                                    onChange={event => { this.handleChange(event); }}
                                                                    value={this.state.addresses.address.documentType}
                                                                />

                                                                {this.addressValidator.message('cmbDocumentType', this.state.addresses.address.documentType, 'required|documentType', false, { default: translate('supplier.supplierDocumentTypeRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtDocument" className="validationRequired"> {translate('supplier.supplierDocument')} </label>

                                                                <div className="form-line">
                                                                    <input id="txtDocument" name="addresses.address.document" maxLength='19' onChange={this.handlechangeMask} className="form-control js-change" autoComplete="off" placeholder={translate('supplier.supplierDocumentPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.document', this.state.addresses.address.document, 'required|document|documentAdded', false, { default: translate('supplier.supplierDocumentRequired'), document: this.state.addresses.address.documentType == 0 ? translate('supplier.supplierCNPJInvalid') : translate('supplier.supplierCPFInvalid'), documentAdded: translate('supplier.supplierDocumentAdded') })}
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtStreetName">{translate('supplier.supplierAddressStreet')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtStreetName" name="addresses.address.street" className="form-control js-change" placeholder={translate('supplier.supplierAddressStreetPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.street', this.state.addresses.address.street, 'required', false, { default: translate('supplier.supplierAddressStreetRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtStreetNumber">{translate('supplier.supplierAddressNumber')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtStreetNumber" name="addresses.address.number" className="form-control js-change js-integer" placeholder={translate('supplier.supplierAddressNumberPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtDistrict">{translate('supplier.supplierAddressDistrict')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtDistrict" name="addresses.address.district" className="form-control js-change" placeholder={translate('supplier.supplierAddressDistrictPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtZipCode">{translate('supplier.supplierAddressZipCode')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtZipCode" name="addresses.address.zipCode" className="form-control js-change js-zipcode" placeholder={translate('supplier.supplierAddressZipCodePlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.zipCode', this.state.addresses.address.zipCode, 'required', false, { default: translate('supplier.supplierAddressZipCodeRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtCity">{translate('supplier.supplierAddressCity')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtCity" name="addresses.address.city" className="form-control js-change" placeholder={translate('supplier.supplierAddressCityPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.city', this.state.addresses.address.city, 'required', false, { default: translate('supplier.supplierAddressCityRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtComplement">{translate('supplier.supplierAddressComplement')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtComplement" name="addresses.address.complement" className="form-control js-change" placeholder={translate('supplier.supplierAddressComplementPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-6">
                                                            <div className="m-b-20">
                                                                <label className="validationRequired" htmlFor="cmbAddressCountry">{translate('supplier.supplierAddressCountry')}</label>

                                                                <RCSelect
                                                                    id="cmbAddressCountry"
                                                                    name="addresses.address.country.id"
                                                                    hasEmptyOption={true}
                                                                    isInt={true}
                                                                    options={this.state.countries}
                                                                    optionValue="id"
                                                                    optionLabel="name"
                                                                    placeholder={translate('supplier.supplierAddressCountryPlaceHolder')}
                                                                    onChange={event => { this.handleChange(event); }}
                                                                    value={this.state.addresses.address.country.id}
                                                                />

                                                                {this.addressValidator.message('addresses.address.country.id', this.state.addresses.address.country.id, 'gt: 0', false, { default: translate('supplier.supplierAddressCountryRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="m-b-20">
                                                                <label htmlFor="cmbAddressState" className={this.state.addressStates && this.state.addressStates.length > 0 ? 'validationRequired' : ''}>
                                                                    {translate('supplier.supplierAddressState')}
                                                                </label>

                                                                <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.addressStatesLoading ? 'inline-block' : 'none' }}>
                                                                    <div className="spinner-layer">
                                                                        <div className="circle-clipper left">
                                                                            <div className="circle"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <RCSelect
                                                                    id="cmbAddressState"
                                                                    name="addresses.address.state.id"
                                                                    hasEmptyOption={true}
                                                                    isInt={true}
                                                                    options={this.state.addressStates != null ? this.state.addressStates : this.state.states}
                                                                    optionValue="id"
                                                                    optionLabel="displayName"
                                                                    placeholder={translate('supplier.supplierAddressStatePlaceHolder')}
                                                                    onChange={event => { this.handleChange(event); }}
                                                                    value={this.state.addresses.address.state.id}
                                                                />

                                                                {this.addressValidator.message('cmbAddressState', this.state.addresses.address.state.id, 'gt: 0|state', false, { default: translate('supplier.supplierAddressStateRequired') })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row clearfix">

                                                        <div className="col-md-3">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtAddressPhoneNumber">{translate('supplier.supplierContactPhoneNumber')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtAddressPhoneNumber" maxLength="15" className="form-control js-change js-phone-number" name="addresses.address.phoneNumber" placeholder={translate('supplier.supplierContactPhoneNumberPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.phoneNumber', this.state.addresses.address.phoneNumber, 'required', false, { default: translate('supplier.supplierContactPhoneNumberRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <div className="input-group">
                                                                <label htmlFor="txtAddressEmail">{translate('supplier.supplierEmail')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtAddressEmail" className="form-control js-change" name="addresses.address.email" placeholder={translate('supplier.supplierEmailPlaceHolder')} onKeyUp={this.handleTypingEmail} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.email', this.state.addresses.address.email, 'email', false, { default: translate('supplier.supplierEmailInvalid') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtStateRegistration"> {translate('supplier.supplierAddressStateRegistration')} </label>

                                                                <div className="form-line">
                                                                    <input id="txtStateRegistration" name="addresses.address.stateRegistration" className="form-control js-change" autoComplete="off" placeholder={translate('supplier.supplierAddressStateRegistrationPlaceHolder')} type="text" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn m-r-10" onClick={this.resetAddress} style={{ display: this.state.addresses.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                                <i className="material-icons">&#xe5c4;</i>
                                                                <span>{translate('forms.buttonCancel')}</span>
                                                            </button>

                                                            <button type="button" className="btn btn-primary" onClick={this.addAddress}>
                                                                <i className="material-icons">&#xe145;</i>
                                                                <span>{translate(this.state.addresses.rowIndex < 0 ? 'supplier.supplierAddressesAddNew' : 'supplier.supplierAddressesUpdate')}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <ReactTable
                                            data={this.state.supplier.address}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('supplier.supplierAddressName'),
                                                            accessor: "name",
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierDocument'),
                                                            accessor: "document",
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierAddressState'),
                                                            accessor: "state.name",

                                                        },
                                                        {
                                                            Header: translate('supplier.supplierAddressCity'),
                                                            accessor: "city",

                                                        },
                                                        {
                                                            Header: translate('supplier.supplierAddressDistrict'),
                                                            accessor: "district",

                                                        },                                                        
                                                        {
                                                            Header: translate('supplier.supplierAddressIsMaster'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isMaster &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isMaster &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        session.auth([{ type: "Supplier", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteAddress(row.index) }}>
                                                                        <i className="material-icons">&#xe872;</i>
                                                                    </button>

                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editAddress(row.index) }}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            SubComponent={row => {
                                                return <div className="m-l-30 m-r-30 m-t-30">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('supplier.supplierAddressCountry')}:
																<b> {row.original.country.name}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <p>
                                                                {translate('supplier.supplierLegalName')}:
																<b> {row.original.legalName}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <p>
                                                                {translate('supplier.supplierAddressStreet')}:
																<b> {row.original.street}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('supplier.supplierAddressZipCode')}:
																<b> {row.original.zipCode}</b>
                                                            </p>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <p>
                                                                {translate('supplier.supplierAddressNumber')}:
																<b> {row.original.number}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <p>
                                                                {translate('supplier.supplierAddressComplement')}:
																<b> {row.original.complement}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('supplier.supplierContactPhoneNumber')}:
																<b> {row.original.phoneNumber}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <p>
                                                                {translate('supplier.supplierEmail')}:
																<b> {row.original.email}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <p>
                                                                {translate('supplier.supplierAddressStateRegistration')}:
																<b> {row.original.stateRegistration}</b>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            }}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}
                                        />
                                    </div>

                                    <div id="contactsPanel" className="tab-pane fade in" role="tabpanel">
                                        {
                                            session.auth([{ type: "Supplier", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('supplier.supplierContactsTitleDescription')}</small>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="row clearfix">
                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtContactName">{translate('supplier.supplierContactName')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactName" className="form-control js-change" name="contacts.contact.name" placeholder={translate('supplier.supplierContactNamePlaceHolder')} type="text" />
                                                                </div>

                                                                {this.contactValidator.message('contacts.contact.name', this.state.contacts.contact.name, 'required', false, { default: translate('supplier.supplierContactNameRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtContactPosition">{translate('supplier.supplierContactPosition')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactPosition" className="form-control js-change" name="contacts.contact.position" placeholder={translate('supplier.supplierContactPositionPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="m-t-30">
                                                                <input id="chkContactIsMaster" name="contacts.contact.isMaster" type="checkbox" checked={this.state.contacts.contact.isMaster} onChange={this.handleChange} />
                                                                <label htmlFor="chkContactIsMaster">{translate('supplier.supplierContactIsMaster')}</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="m-t-30">
                                                                <input id="chkContactIsActive" name="contacts.contact.isActive" type="checkbox" checked={this.state.contacts.contact.isActive} onChange={this.handleChange} />
                                                                <label htmlFor="chkContactIsActive">{translate('supplier.supplierIsActive')}</label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row clearfix">

                                                        <div className="col-md-3">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtContactPhoneNumber">{translate('supplier.supplierContactPhoneNumber')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactPhoneNumber" maxLength="15" className="form-control js-change js-phone-number" name="contacts.contact.phoneNumber" placeholder={translate('supplier.supplierContactPhoneNumberPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.contactValidator.message('contacts.contact.phoneNumber', this.state.contacts.contact.phoneNumber, 'required', false, { default: translate('supplier.supplierContactPhoneNumberRequired') })}
                                                            </div>
                                                        </div>


                                                        <div className="col-md-5">
                                                            <div className="input-group">
                                                                <label htmlFor="txtContactEmails">{translate('supplier.supplierContactEmails')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactEmails" className="form-control js-change" name="contacts.contact.emails" placeholder={translate('supplier.supplierContactEmailsPlaceHolder')} onKeyUp={this.handleTypingEmail} type="text" />
                                                                </div>

                                                                {this.contactValidator.message('contacts.contact.emails', this.state.contacts.contact.emails, 'emails', false, { default: translate('supplier.supplierContactEmailsInvalid') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtContactNotes">{translate('supplier.supplierContactNotes')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactNotes" className="form-control js-change" name="contacts.contact.notes" placeholder={translate('supplier.supplierContactNotesPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn m-r-10" onClick={this.resetContact} style={{ display: this.state.contacts.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                                <i className="material-icons">&#xe5c4;</i>
                                                                <span>{translate('forms.buttonCancel')}</span>
                                                            </button>

                                                            <button type="button" className="btn btn-primary" onClick={this.addContact}>
                                                                <i className="material-icons">&#xe145;</i>
                                                                <span>{translate(this.state.contacts.rowIndex < 0 ? 'supplier.supplierContactsAddNew' : 'supplier.supplierContactsUpdate')}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <ReactTable
                                            data={this.state.supplier.contact}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('supplier.supplierContactName'),
                                                            id: "name",
                                                            accessor: data => { return <span title={data.name}> {data.name} </span> }
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierContactPosition'),
                                                            accessor: "position",
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierContactPhoneNumber'),
                                                            accessor: "phoneNumber",
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierContactEmails'),
                                                            id: "emails",
                                                            accessor: data => { return <span title={data.emails}> {data.emails} </span> }
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierContactNotes'),
                                                            id: "notes",
                                                            accessor: data => { return <span title={data.notes}> {data.notes} </span> }
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierContactIsMaster'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isMaster &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isMaster &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierIsActive'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isActive &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isActive &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        session.auth([{ type: "Supplier", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteContact(row.index) }}>
                                                                        <i className="material-icons">&#xe872;</i>
                                                                    </button>

                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editContact(row.index) }}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}
                                        />
                                    </div>

                                    <div id="bankAccountsPanel" className="tab-pane fade in" role="tabpanel">
                                        {
                                            session.auth([{ type: "Supplier", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('supplier.supplierSetupAccountTitleDescription')}</small>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="row clearfix">

                                                        <div className="col-md-4">
                                                            <div className="m-b-20">
                                                                <label htmlFor="cmbBankNumber" className="validationRequired">
                                                                    {translate('supplier.supplierSetupAccountBankNumber')}
                                                                </label>

                                                                <RCSelect
                                                                    id="cmbBankNumber"
                                                                    name="bankAccounts.bankAccount.bankNumber"
                                                                    hasEmptyOption={true}
                                                                    isInt={false}
                                                                    options={enumsBank.getBankNumber()}
                                                                    optionValue="index"
                                                                    optionLabel="text"
                                                                    placeholder={translate('supplier.supplierSetupAccountBankNumberPlaceHolder')}
                                                                    onChange={event => { this.handleChange(event); }}
                                                                    value={this.state.bankAccounts.bankAccount.bankNumber}
                                                                />

                                                                {this.bankAccountValidator.message('cmbBankNumber', this.state.bankAccounts.bankAccount.bankNumber, 'required|bankNumber', false, { default: translate('supplier.supplierSetupAccountBankNumberRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtBankAccountName">{translate('supplier.supplierSetupAccountName')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtBankAccountName" maxLength="200" className="form-control js-change" name="bankAccounts.bankAccount.name" placeholder={translate('supplier.supplierSetupAccountNamePlaceHolder')} type="text" />
                                                                </div>

                                                                {this.bankAccountValidator.message('bankAccounts.bankAccount.name', this.state.bankAccounts.bankAccount.name, 'required', false, { default: translate('supplier.supplierSetupAccountNameRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="m-t-30">
                                                                <input id="chkBankAccountIsMaster" name="bankAccounts.bankAccount.isMaster" type="checkbox" checked={this.state.bankAccounts.bankAccount.isMaster} onChange={this.handleChange} />
                                                                <label htmlFor="chkBankAccountIsMaster">{translate('supplier.supplierSetupAccountIsMaster')}</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="m-t-30">
                                                                <input id="chkBankAccountIsActive" name="bankAccounts.bankAccount.isActive" type="checkbox" checked={this.state.bankAccounts.bankAccount.isActive} onChange={this.handleChange} />
                                                                <label htmlFor="chkBankAccountIsActive">{translate('supplier.supplierSetupAccountIsActive')}</label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row clearfix">

                                                        <div className="col-md-3">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtBankAccountAgencyNumber">{translate('supplier.supplierSetupAccountAgencyNumber')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtBankAccountAgencyNumber" maxLength="4" className="form-control js-change js-integer" name="bankAccounts.bankAccount.agencyNumber" placeholder={translate('supplier.supplierSetupAccountAgencyNumberPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.bankAccountValidator.message('bankAccounts.bankAccount.agencyNumber', this.state.bankAccounts.bankAccount.agencyNumber, 'required', false, { default: translate('supplier.supplierSetupAccountAgencyNumberRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="input-group">
                                                                <label htmlFor="txtBankAccountNumber">{translate('supplier.supplierSetupAccountNumber')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtBankAccountNumber" maxLength="20" className="form-control js-change js-integer" name="bankAccounts.bankAccount.accountNumber" placeholder={translate('supplier.supplierSetupAccountNumberPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.bankAccountValidator.message('bankAccounts.bankAccount.accountNumber', this.state.bankAccounts.bankAccount.accountNumber, 'required', false, { default: translate('supplier.supplierSetupAccountNumberRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="input-group">
                                                                <label htmlFor="txtBankAccountsNotes">{translate('supplier.supplierSetupAccountNotes')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtBankAccountsNotes" className="form-control js-change" name="bankAccounts.bankAccount.notes" placeholder={translate('supplier.supplierSetupAccountNotesPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn m-r-10" onClick={this.resetBankAccount} style={{ display: this.state.bankAccounts.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                                <i className="material-icons">&#xe5c4;</i>
                                                                <span>{translate('forms.buttonCancel')}</span>
                                                            </button>

                                                            <button type="button" className="btn btn-primary" onClick={this.addBankAccount}>
                                                                <i className="material-icons">&#xe145;</i>
                                                                <span>{translate(this.state.bankAccounts.rowIndex < 0 ? 'supplier.supplierSetupAccountsAddNew' : 'supplier.supplierSetupAccountsUpdate')}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <ReactTable
                                            data={this.state.supplier.bankAccount}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('supplier.supplierSetupAccountName'),
                                                            id: "name",
                                                            accessor: data => { return <span title={data.name}> {data.name} </span> }
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierSetupAccountBankNumber'),
                                                            id: "bankNumber",
                                                            accessor: data => { return <span title={enumsBank.getBankNumber().find(x => { return x.index === data.bankNumber; }).text}> {enumsBank.getBankNumber().find(x => { return x.index === data.bankNumber; }).text} </span> }
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierSetupAccountAgencyNumber'),
                                                            accessor: "agencyNumber",
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierSetupAccountNumber'),
                                                            accessor: "accountNumber",
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierSetupAccountNotes'),
                                                            id: "notes",
                                                            accessor: data => { return <span title={data.notes}> {data.notes} </span> }
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierSetupAccountIsMaster'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isMaster &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isMaster &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: translate('supplier.supplierSetupAccountIsActive'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isActive &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isActive &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        session.auth([{ type: "Supplier", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteBankAccount(row.index) }}>
                                                                        <i className="material-icons">&#xe872;</i>
                                                                    </button>

                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editBankAccount(row.index) }}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}
                                        />
                                    </div>

                                </div>

                                <div className="row clearfix">
                                    <div className="col-md-12 formActions">
                                        <Link to="/suppliers" className="btn btn-default waves-effect">
                                            <i className="material-icons">&#xe5c4;</i>
                                            <span>{translate('forms.buttonReturn')}</span>
                                        </Link>

                                        {(session.isAdmin() || session.isOperator()) && session.auth([{ type: "Supplier", value: "UpdateRate" }]) &&
                                            <button className="btn bg-deep-purple waves-effect m-l-10" style={{ display: this.id ? 'inline-block' : 'none' }} type="button" onClick={() => { this.handleEditRate(); }}>
                                            <i className="material-icons">&#xe042;</i>
                                                <span>{translate('forms.updateSupplierRate')}</span>
                                            </button>
                                        }

                                        {session.auth([{ type: "Supplier", value: "Save" }]) &&
                                            <button type="button" className="btn btn-success waves-effect" disabled={this.state.submitLoading} onClick={this.handleSubmit}>
                                            <i className="material-icons">&#xe161;</i>
                                                <span>{translate('forms.buttonSave')}</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Rate */}
                    <Modal
                        open={this.state.rate.open}
                        closeOnEsc={false}
                        onClose={() => { }}
                        onEntered={() => { this.forceUpdate(); }}
                        closeIconSize={0}
                        center={true}
                        styles={{ modal: { maxWidth: '60%' } }}
                    >
                        <div>
                            <div style={{ width: 9999 }}></div>

                            <div>
                                <h4>{translate('forms.updateSupplierRate')}</h4>
                                <hr />
                            </div>

                            <div>
                                <div className="row">

                                    <div className="col-md-5">
                                        <div className="m-b-5">
                                            <label className="validationRequired" htmlFor="cmbCustomer"> {translate('supplier.supplierCustomer')} </label>

                                            <RCSelect
                                                id="cmbCustomer"
                                                name="rate.customerId"
                                                hasEmptyOption={true}
                                                isInt={true}
                                                options={this.state.customerSuppliers}
                                                optionValue="customerId"
                                                optionLabel="customerName"
                                                placeholder={translate('supplier.supplierCustomerPlaceHolder')}
                                                onChange={event => { this.handleChange(event); }}
                                                value={this.state.rate.customerId}
                                            />

                                            {this.rateValidator.message('rate.customerId', this.state.rate.customerId, 'gt: 0', false, { default: translate('supplier.supplierCustomerRequired') })}

                                        </div>
                                    </div>

                                    <div className="col-md-4" style={{ display: this.state.rate.customerId > 0 ? 'block' : 'none' }}>
                                        <div className="input-group">
                                            <label> {translate('supplier.supplierClassification')} </label>

                                            <div className="form-line">
                                                <input id="txtClassification" disabled className="form-control js-change" name="rate.customerClassification.displayName" type="text" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-3" style={{ display: this.state.rate.customerId > 0 ? 'block' : 'none' }}>
                                        <div className="input-group">
                                            <label className="validationRequired" htmlFor="txtRate">{translate('supplier.supplierRate')}</label>

                                            <div className="form-line">
                                                <input id="txtRate" className="form-control js-change js-decimal" name="rate.currentRate" placeholder={translate('supplier.supplierRatePlaceHolder')} type="text" />
                                            </div>

                                            {this.rateValidator.message('rate.currentRate', this.state.rate.currentRate, 'required|rate', false, { default: translate('supplier.supplierRateRequired'), rate: translate('supplier.supplierRateClassification') })}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="align-right">
                                <div className="m-t-20">

                                    <button type="button" className="m-r-10 btn btn-default waves-effect" disabled={this.state.submitLoading} onClick={() => { this.handleReturnRate(); }}>
                                        <i className="material-icons">&#xe5c4;</i>
                                        <span>{translate('forms.buttonReturn')}</span>
                                    </button>

                                    <button type="button" className="btn btn-success waves-effect" disabled={this.state.submitLoading} onClick={() => { this.handleSaveRate(); }}>
                                        <i className="material-icons">&#xe161;</i>
                                        <span>{translate('forms.buttonSave')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <ToastContainer hideProgressBar />
                </div>
            )

        } else {

            return (
                <div className="preloader pl-size-lg align-center">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>

                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {

    function handleClick() {

        tabToShow.trigger('click');
        closeToast();
    }

    return (

        <div onClick={handleClick}>
            {message}
        </div>
    );
}

export default SupplierPage;