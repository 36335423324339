import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { translate } from 'react-internationalization';
import { ToastContainer, toast } from 'react-toastify';
import ReactTable from "react-table";
import SimpleReactValidator from 'simple-react-validator'
import $ from 'jquery';
import RCSelect from '../components/select';
import * as session from '../components/SessionValidator';
import { cnpj } from '../components/cpf-cnpj-validator';
import { cpf_cnpj_Mask } from '../components/mask'
import { submitReportDailyLog } from './helper';
import * as enums from '../components/enums/bank';

const moment = window.Moment;

class BankPage extends React.Component {

    constructor(props) {

        super(props);

        this.id = this.props.match.params.id;
        this.token = session.getToken();

        this.setValidators();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOnlyInt = this.handleChangeOnlyInt.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.addAddress = this.addAddress.bind(this);
        this.resetAddress = this.resetAddress.bind(this);

        this.handlechangeMask = this.handlechangeMask.bind(this)

        this.addContact = this.addContact.bind(this);
        this.resetContact = this.resetContact.bind(this);

        this.state = {

            bank: {
                cnpj: '',
                phoneNumber: '',
                email: '',
                stateRegistration: ''
            },

            countries: null,
            addressStates: null,

            contacts: {

                rowIndex: -1,
                contact: {
                    name: '',
                    position: '',
                    phoneNumber: '',
                    emails: '',
                    notes: '',
                    isActive: true,
                    isMaster: true
                }
            },

            addresses: {

                rowIndex: -1,
                address: {

                    name: '',
                    country: {},
                    state: {},
                    city: '',
                    district: '',
                    street: '',
                    number: '',
                    complement: '',
                    zipCode: '',
                    isMaster: true
                }
            },

            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            }
        };
    }

    handlechangeMask(e) {

        let bank = this.state.bank;

        bank.cnpj = cpf_cnpj_Mask(e.target.value);

        this.setState({ bank: bank });
    }

    async componentDidMount() {

        let _this = this;
        let reportDailyLog = this.state.reportDailyLog;

        $('body').on('change', '.js-change', function (event) { _this.handleChange(event); });

        fetch(window.$CustomerServiceURL + '/api/Country/List',
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {

                res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                this.setState({ countries: res.data });
                this.forceUpdate();
            });

        if (this.id) {

            await fetch(window.$BankServiceURL + '/api/bank/' + this.id,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    if (res.status === 401) {
                        session.sessionExpired();
                        res = [];

                        return res;
                    }

                    if (res.status === 403) {
                        session.accessDenied();
                        res = [];

                        return res;
                    }

                    return res.json();

                })
                .then(res => {

                    reportDailyLog.objectBefore = JSON.stringify(res.data);

                    this.setState({ bank: res.data, reportDailyLog: reportDailyLog });
                    this.forceUpdate();
                });

        } else {

            let bank = {

                isActive: true,
                address: [],
                contact: [],
                phoneNumber: '',
                email: '',
                stateRegistration: ''
            }

            this.setState({ bank: bank });
            this.forceUpdate();
        }
    }

    async componentDidUpdate() {

        let _this = this;

        $('.js-change').each(function () { _this.setValue(this); });
        $('.js-phone-number').inputmask({ greedy: false, mask: '(99) 9999[9]-9999', showMaskOnHover: false });
        $('.js-integer').inputmask({ greedy: false, mask: '9', repeat: '*', showMaskOnHover: false });
        $('.js-decimal').inputmask('decimal', { digits: 4, digitsOptional: false, max: 999999999, placeholder: '0.00', rightAlign: false, showMaskOnHover: false });
        $('.js-letters').inputmask({ greedy: false, mask: 'a', repeat: '*', showMaskOnHover: false });
        $('.js-zipcode').inputmask({ greedy: false, mask: '99999-999', showMaskOnHover: false });
    }

    async handleChange(event) {

        session.validateSession();

        if (event.target.id === "cmbAddressCountry") {

            this.state.addresses.address.state = {};
            this.state.addresses.address.country = this.state.countries.find(item => { return item.id === event.target.value; });;
            this.state.addressStates = null;

            if (event.target.value > 0) this.loadStates('addressStates', 'addressStatesLoading', event.target.value);
        }

        if (event.target.id === "cmbAddressState") {

            this.state.addresses.address.state = this.state.addressStates.find(item => { return item.id === event.target.value; });;
        }

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;
            }
        });

        this.setState(this.state);
    }

    handleChangeOnlyInt(event) {

        let value = event.target.value;
        value = value.match(/\d+/g) == null ? '' : value.match(/\d+/g).join([]);
        event.target.value = value;

        this.handleChange(event);
    }

    handleTypingEmail(e) {
        e.target.value = e.target.value.toLowerCase()
    }

    setValue(element) {

        let keys = element.name.split('.'), property = this.state;

        keys.forEach(key => {

            property = property[key];
            if (Array.isArray(property)) property = property[element.dataset.index];

        });

        $(element).val(property);
    }

    handleSubmit() {

        if (!this.validator.allValid()) {

            this.validator.showMessages();
            this.forceUpdate();

            return;
        }

        if (this.state.bank.address.length <= 0) {
            toast.error(<ToastRedirect message={translate('bank.bankAddressesRequired')} tabToShow={$('#addresses-tab-link')} />);
            $('#addresses-tab-link').addClass('invalid')
            return;
        }

        var bank = $.extend(true, {}, this.state.bank),
            method = this.id ? 'Update' : 'Create',
            status = 0;

        let reportDailyLog = this.state.reportDailyLog;

        this.setState({ submitLoading: true });

        fetch(window.$BankServiceURL + '/api/Bank/' + method,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                body: JSON.stringify(bank),
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                status = res.status;
                this.setState({ submitLoading: false });

                switch (status) {

                    case 401:
                        session.sessionExpired();
                        res = [];
                        return res;

                        break;

                    case 403:
                        session.accessDenied();
                        res = [];
                        return res;

                        break;

                    default:
                        return res.json();
                }

            }).then(res => {

                switch (status) {

                    case 200:
                        this.props.history.push('/banks');
                        toast.success(translate(!this.id ? 'bank.bankCreated' : 'bank.bankUpdated'));

                        reportDailyLog.action = this.id ? 15 : 14;//14 = createBank 15 = updateBank
                        reportDailyLog.objectAfter = JSON.stringify(bank);
                        submitReportDailyLog(reportDailyLog);

                        break;

                    case 400:
                    case 409:
                        var messages = res.data;
                        messages.forEach(ex => toast.warn(ex.message));
                        break;

                    case 500:
                        toast.error('error');
                        break;
                }

            }).catch(err => {
                console.error(err);
            });
    }

    setValidators() {

        this.validator = new SimpleReactValidator({

            cnpj: {

                rule: value => {

                    return cnpj.isValid(value);
                }
            },

            bankNumber: {

                rule: (value) => {

                    return ['', null, undefined].indexOf(value) < 0;
                }
            },

            email: {

                rule: function (val) {

                    if (val === '')
                        return true;

                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val.trim()))
                        return false;

                    return true;
                }
            }
        });

        this.contactValidator = new SimpleReactValidator({

            emails: {

                rule: function (val) {

                    if (val === '') return true;
                    var emails = val.replace(new RegExp(',', 'g'), ';').split(";");
                    for (var i = 0, len = emails.length; i < len; i++) if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emails[i].trim())) return false;

                    return true;
                }
            }
        });

        this.addressValidator = new SimpleReactValidator({

            state: {

                rule: value => {

                    return !this.state.addressStates || this.state.addressStates.length <= 0 || parseInt(value) > 0;
                }
            },

        });
    }

    addAddress() {

        if (!this.addressValidator.allValid()) {

            this.addressValidator.showMessages();
            this.forceUpdate();
            return;
        }

        var address = JSON.parse(JSON.stringify(this.state.addresses.address));

        if (address.isMaster) {

            //desabilita todos
            for (var i = 0; i < this.state.bank.address.length; i++) {

                this.state.bank.address[i].isMaster = false;
            }
        }

        if (this.state.addresses.rowIndex < 0) {

            this.state.bank.address.push(address);

        } else {

            this.state.bank.address[this.state.addresses.rowIndex] = address;
        }

        this.resetAddress();
    }

    editAddress(rowIndex) {

        this.state.addresses.address = JSON.parse(JSON.stringify(this.state.bank.address[rowIndex]));
        this.state.addresses.rowIndex = rowIndex;

        if (this.state.bank.address[rowIndex].country.id > 0) this.loadStates('addressStates', 'addressStatesLoading', this.state.bank.address[rowIndex].country.id);
    }

    deleteAddress(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        var items = this.state.bank.address;
                        items.splice(rowIndex, 1);
                        this.state.bank.address = items;

                        if (this.state.addresses.rowIndex === rowIndex) {
                            this.resetAddress();

                        } else {
                            this.setState(this.state);
                        }
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('bank.bankAddressDeleteTitle'),
            title: translate('bank.bankAddressDeleteQuestion')

        });

    }

    resetAddress() {

        this.state.addressStates = null;

        this.state.addresses = {

            rowIndex: -1,
            address: {

                name: '',
                country: {},
                state: {},
                city: '',
                district: '',
                street: '',
                number: '',
                complement: '',
                zipCode: '',
                isMaster: true
            }
        };

        if (this.addressValidator !== undefined) {
            this.addressValidator.hideMessages();
        }

        this.setState(this.state);
    }

    addContact() {

        if (!this.contactValidator.allValid()) {

            this.contactValidator.showMessages();
            this.forceUpdate();

            return;
        }

        var contact = JSON.parse(JSON.stringify(this.state.contacts.contact));

        if (contact.isMaster) {

            //desabilita todos
            for (var i = 0; i < this.state.bank.contact.length; i++) {

                this.state.bank.contact[i].isMaster = false;
            }
        }

        if (this.state.contacts.rowIndex < 0) {

            this.state.bank.contact.push(contact);

        } else {

            this.state.bank.contact[this.state.contacts.rowIndex] = contact;
        }

        this.resetContact();
    }

    editContact(rowIndex) {

        this.state.contacts.contact = JSON.parse(JSON.stringify(this.state.bank.contact[rowIndex]));
        this.state.contacts.rowIndex = rowIndex;

        this.setState(this.state);
    }

    deleteContact(rowIndex) {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonYes'),
                    onClick: () => {

                        var items = this.state.bank.contact;
                        items.splice(rowIndex, 1);
                        this.state.bank.contact = items;

                        if (this.state.contacts.rowIndex === rowIndex) { this.resetContact(); } else { this.setState(this.state); }
                    }
                },
                {
                    label: translate('forms.buttonNo'),
                    onClick: () => { window.close(); }
                }
            ],
            message: translate('bank.bankContactDeleteTitle'),
            title: translate('bank.bankContactDeleteQuestion')
        });

    }

    resetContact() {

        this.state.contacts = {

            rowIndex: -1,
            contact: {
                name: '',
                position: '',
                phoneNumber: '',
                emails: '',
                notes: '',
                isActive: true,
                isMaster: true
            }
        };

        if (this.contactValidator !== undefined) {
            this.contactValidator.hideMessages();
        }

        this.setState(this.state);
    }

    async loadStates(states, statesLoading, countryId) {

        this.state[statesLoading] = true;
        this.setState(this.state);

        fetch(window.$CustomerServiceURL + '/api/State/List?countryId=' + countryId,
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];

                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];

                    return res;
                }

                return res.json();

            })
            .then(res => {

                this.state[states] = res.data;
                this.state[statesLoading] = false;

                this.setState(this.state);
                this.forceUpdate();

            });
    }

    render() {

        var fetched = this.state != null && this.state.bank != null && this.state.countries != null;

        if (fetched) {

            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    <ul className="nav nav-tabs tab-nav-right" role="tablist">
                                        <li className="active" role="presentation">
                                            <a href="#bankPanel" data-toggle="tab">{translate('bank.bankTitle')}</a>
                                        </li>

                                        <li role="presentation">
                                            <a id="addresses-tab-link" href="#addressPanel" data-toggle="tab">{translate('bank.bankAddressNameTitle')}</a>
                                        </li>

                                        <li role="presentation">
                                            <a href="#contactsPanel" data-toggle="tab">{translate('bank.bankContactsTitle')}</a>
                                        </li>
                                    </ul>
                                </h2>

                                <div className="header-dropdown">
                                    <div className="preloader pl-size-xs" style={{ display: this.state.submitLoading ? 'block' : 'none' }}>
                                        <div className="spinner-layer">

                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>

                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <div className="tab-content">
                                    <div id="bankPanel" className="tab-pane fade in active" role="tabpanel">
                                        <div>
                                            <div className="m-b-40">
                                                <small>{translate('bank.bankTitleDescription')}</small>
                                            </div>

                                            <div className="row clearfix">

                                                <div className="col-md-4">
                                                    <div className="m-b-20">
                                                        <label htmlFor="cmbBankNumber" className="validationRequired">
                                                            {translate('bank.bankNumber')}
                                                        </label>

                                                        <RCSelect
                                                            id="cmbBankNumber"
                                                            name="bank.bankNumber"
                                                            hasEmptyOption={true}
                                                            isInt={false}
                                                            options={enums.getBankNumber()}
                                                            optionValue="index"
                                                            optionLabel="text"
                                                            placeholder={translate('bank.bankNumberPlaceHolder')}
                                                            onChange={event => { this.handleChange(event); }}
                                                            value={this.state.bank.bankNumber}
                                                            isDisabled={!session.auth([{ type: "Bank", value: "Save" }])}
                                                        />

                                                        {this.validator.message('cmbBankNumber', this.state.bank.bankNumber, 'required|bankNumber', false, { default: translate('bank.bankNumberRequired') })}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="input-group">
                                                        <label className="validationRequired" htmlFor="txtName">{translate('bank.bankName')}</label>

                                                        <div className="form-line">
                                                            <input id="txtName" disabled={!session.auth([{ type: "Bank", value: "Save" }])} className="form-control js-change" name="bank.legalName" placeholder={translate('bank.bankNamePlaceHolder')} type="text" />
                                                        </div>

                                                        {this.validator.message('bank.legalName', this.state.bank.legalName, 'required', 'validationMessage--error', { default: translate('bank.bankNameRequired') })}
                                                    </div>
                                                </div>                                               

                                                {(session.isAdmin() || session.isOperator()) &&
                                                    <div className="col-md-2">
                                                        <div className="m-t-30">
                                                            <input id="chkIsActive" name="bank.isActive" type="checkbox" checked={this.state.bank.isActive} onChange={this.handleChange} />
                                                            <label htmlFor="chkIsActive">{translate('bank.bankIsActive')}</label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="row clearfix">
                                                <div className="col-md-3">
                                                    <div className="input-group">
                                                        <label htmlFor="txtCnpj">{translate('bank.bankCNPJ')}</label>

                                                        <div className="form-line">
                                                            <input id="txtCnpj" disabled={!session.auth([{ type: "Bank", value: "Save" }])} value={this.state.bank.cnpj} maxLength='19' onChange={this.handlechangeMask} className="form-control" name="bank.cnpj" placeholder={translate('bank.bankCNPJPlaceHolder')} type="text" />
                                                        </div>

                                                        {this.validator.message('bank.cnpj', this.state.bank.cnpj, 'required|cnpj', 'validationMessage--error', { default: translate('bank.bankCNPJRequired'), cnpj: translate('bank.bankCNPJInvalid') })}
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="input-group">
                                                        <label htmlFor="txtAgencyNumber">{translate('bank.bankAgencyNumber')}</label>

                                                        <div className="form-line">
                                                            <input id="txtAgencyNumber" disabled={!session.auth([{ type: "Bank", value: "Save" }])} maxLength="4" className="form-control js-change js-integer" name="bank.agencyNumber" placeholder={translate('bank.bankAgencyNumberPlaceHolder')} type="text" />
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="input-group">
                                                        <label htmlFor="txtAccountNumber">{translate('bank.bankAccountNumber')}</label>

                                                        <div className="form-line">
                                                            <input id="txtAccountNumber" disabled={!session.auth([{ type: "Bank", value: "Save" }])} maxLength="20" className="form-control js-change js-integer" name="bank.accountNumber" placeholder={translate('bank.bankAccountNumberPlaceHolder')} type="text" />
                                                        </div>

                                                    </div>
                                                </div>

                                                {(session.isAdmin() || session.isOperator()) &&
                                                    <div className="col-md-3">
                                                        <div className="input-group">
                                                            <label htmlFor="txtPortalRate">{translate('bank.bankPortalRate')}</label>

                                                            <div className="form-line">
                                                                <input id="txtPortalRate" className="form-control js-change js-decimal" name="bank.portalRate" placeholder={translate('bank.bankPortalRatePlaceHolder')} type="text" />
                                                            </div>

                                                            {this.validator.message('bank.portalRate', this.state.bank.portalRate, 'required', false, { default: translate('bank.bankPortalRateRequired') })}
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="row clearfix">

                                                <div className="col-md-3">
                                                    <div className="input-group">
                                                        <label className="validationRequired" htmlFor="txtPhoneNumber">{translate('bank.bankContactPhoneNumber')}</label>

                                                        <div className="form-line">
                                                            <input id="txtPhoneNumber" disabled={!session.auth([{ type: "Bank", value: "Save" }])} maxLength="15" className="form-control js-change js-phone-number" name="bank.phoneNumber" placeholder={translate('bank.bankContactPhoneNumberPlaceHolder')} type="text" />
                                                        </div>

                                                        {this.validator.message('bank.phoneNumber', this.state.bank.phoneNumber, 'required', false, { default: translate('bank.bankContactPhoneNumberRequired') })}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="input-group">
                                                        <label htmlFor="txtEmail">{translate('bank.bankEmail')}</label>

                                                        <div className="form-line">
                                                            <input id="txtEmail" disabled={!session.auth([{ type: "Bank", value: "Save" }])} className="form-control js-change" name="bank.email" placeholder={translate('bank.bankEmailPlaceHolder')} onKeyUp={this.handleTypingEmail} type="text" />
                                                        </div>

                                                        {this.validator.message('bank.email', this.state.bank.email, 'email', false, { default: translate('bank.bankEmailInvalid') })}
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="input-group">
                                                        <label htmlFor="txtStateRegistration"> {translate('bank.bankStateRegistration')} </label>

                                                        <div className="form-line">
                                                            <input id="txtStateRegistration" disabled={!session.auth([{ type: "Bank", value: "Save" }])} name="bank.stateRegistration" className="form-control js-change" autoComplete="off" placeholder={translate('bank.bankStateRegistrationPlaceHolder')} type="text" />
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div id="addressPanel" className="tab-pane fade in" role="tabpanel">
                                        {
                                            session.auth([{ type: "Bank", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('bank.bankAddressTitleDescription')}</small>
                                                    </div>
                                                </div>

                                                <div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-10">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtAddressName">{translate('bank.bankAddressName')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtAddressName" name="addresses.address.name" className="form-control js-change" placeholder={translate('bank.bankAddressNamePlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.name', this.state.addresses.address.name, 'required', false, { default: translate('bank.bankAddressNameRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="m-t-30">
                                                                <input id="chkIsMaster" name="addresses.address.isMaster" type="checkbox" checked={this.state.addresses.address.isMaster} onChange={this.handleChange} />
                                                                <label htmlFor="chkIsMaster">{translate('bank.bankAddressIsMaster')}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtStreetName">{translate('bank.bankAddressStreet')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtStreetName" name="addresses.address.street" className="form-control js-change" placeholder={translate('bank.bankAddressStreetPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.street', this.state.addresses.address.street, 'required', false, { default: translate('bank.bankAddressStreetRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtStreetNumber">{translate('bank.bankAddressNumber')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtStreetNumber" name="addresses.address.number" className="form-control js-change js-integer" placeholder={translate('bank.bankAddressNumberPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtDistrict">{translate('bank.bankAddressDistrict')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtDistrict" name="addresses.address.district" className="form-control js-change" placeholder={translate('bank.bankAddressDistrictPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtZipCode">{translate('bank.bankAddressZipCode')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtZipCode" name="addresses.address.zipCode" className="form-control js-change js-zipcode" placeholder={translate('bank.bankAddressZipCodePlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.zipCode', this.state.addresses.address.zipCode, 'required', false, { default: translate('bank.bankAddressZipCodeRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtCity">{translate('bank.bankAddressCity')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtCity" name="addresses.address.city" className="form-control js-change" placeholder={translate('bank.bankAddressCityPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.addressValidator.message('addresses.address.city', this.state.addresses.address.city, 'required', false, { default: translate('bank.bankAddressCityRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtComplement">{translate('bank.bankAddressComplement')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtComplement" name="addresses.address.complement" className="form-control js-change" placeholder={translate('bank.bankAddressComplementPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-6">
                                                            <div className="m-b-20">
                                                                <label className="validationRequired" htmlFor="cmbAddressCountry">{translate('bank.bankAddressCountry')}</label>

                                                                <RCSelect
                                                                    id="cmbAddressCountry"
                                                                    name="addresses.address.country.id"
                                                                    hasEmptyOption={true}
                                                                    isInt={true}
                                                                    options={this.state.countries}
                                                                    optionValue="id"
                                                                    optionLabel="name"
                                                                    placeholder={translate('bank.bankAddressCountryPlaceHolder')}
                                                                    onChange={event => { this.handleChange(event); }}
                                                                    value={this.state.addresses.address.country.id}
                                                                />

                                                                {this.addressValidator.message('addresses.address.country.id', this.state.addresses.address.country.id, 'gt: 0', false, { default: translate('bank.bankAddressCountryRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="m-b-20">
                                                                <label htmlFor="cmbAddressState" className={this.state.addressStates && this.state.addressStates.length > 0 ? 'validationRequired' : ''}>
                                                                    {translate('bank.bankAddressState')}
                                                                </label>

                                                                <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.addressStatesLoading ? 'inline-block' : 'none' }}>
                                                                    <div className="spinner-layer">
                                                                        <div className="circle-clipper left">
                                                                            <div className="circle"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <RCSelect
                                                                    id="cmbAddressState"
                                                                    name="addresses.address.state.id"
                                                                    hasEmptyOption={true}
                                                                    isInt={true}
                                                                    options={this.state.addressStates != null ? this.state.addressStates : this.state.states}
                                                                    optionValue="id"
                                                                    optionLabel="displayName"
                                                                    placeholder={translate('bank.bankAddressStatePlaceHolder')}
                                                                    onChange={event => { this.handleChange(event); }}
                                                                    value={this.state.addresses.address.state.id}
                                                                />

                                                                {this.addressValidator.message('cmbAddressState', this.state.addresses.address.state.id, 'gt: 0', false, { default: translate('bank.bankAddressStateRequired') })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn m-r-10" onClick={this.resetAddress} style={{ display: this.state.addresses.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                                <i className="material-icons">&#xe5c4;</i>
                                                                <span>{translate('forms.buttonCancel')}</span>
                                                            </button>

                                                            <button type="button" className="btn btn-primary" onClick={this.addAddress}>
                                                                <i className="material-icons">&#xe145;</i>
                                                                <span>{translate(this.state.addresses.rowIndex < 0 ? 'bank.bankAddressesAddNew' : 'bank.bankAddressesUpdate')}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <ReactTable
                                            data={this.state.bank.address}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('bank.bankAddressName'),
                                                            accessor: "name",
                                                        },
                                                        {
                                                            Header: translate('bank.bankAddressState'),
                                                            accessor: "state.name",

                                                        },
                                                        {
                                                            Header: translate('bank.bankAddressCity'),
                                                            accessor: "city",

                                                        },
                                                        {
                                                            Header: translate('bank.bankAddressDistrict'),
                                                            accessor: "district",

                                                        },
                                                        {
                                                            Header: translate('bank.bankAddressIsMaster'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isMaster &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isMaster &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        session.auth([{ type: "Bank", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteAddress(row.index) }}>
                                                                        <i className="material-icons">&#xe872;</i>
                                                                    </button>

                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editAddress(row.index) }}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            SubComponent={row => {
                                                return <div className="m-l-30 m-r-30 m-t-30">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('bank.bankAddressCountry')}:
																<b> {row.original.country.name}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <p>
                                                                {translate('bank.bankAddressStreet')}:
																<b> {row.original.street}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <p>
                                                                {translate('bank.bankAddressComplement')}:
																<b> {row.original.complement}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('bank.bankAddressZipCode')}:
																<b> {row.original.zipCode}</b>
                                                            </p>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <p>
                                                                {translate('bank.bankAddressNumber')}:
																<b> {row.original.number}</b>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            }}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}
                                        />
                                    </div>

                                    <div id="contactsPanel" className="tab-pane fade in" role="tabpanel">
                                        {
                                            session.auth([{ type: "Bank", value: "Save" }]) &&
                                            <div>
                                                <div>
                                                    <div className="m-b-40">
                                                        <small>{translate('bank.bankContactsTitleDescription')}</small>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="row clearfix">
                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtContactName">{translate('bank.bankContactName')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactName" className="form-control js-change" name="contacts.contact.name" placeholder={translate('bank.bankContactNamePlaceHolder')} type="text" />
                                                                </div>

                                                                {this.contactValidator.message('contacts.contact.name', this.state.contacts.contact.name, 'required', false, { default: translate('bank.bankContactNameRequired') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtContactPosition">{translate('bank.bankContactPosition')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactPosition" className="form-control js-change" name="contacts.contact.position" placeholder={translate('bank.bankContactPositionPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="m-t-30">
                                                                <input id="chkContactIsMaster" name="contacts.contact.isMaster" type="checkbox" checked={this.state.contacts.contact.isMaster} onChange={this.handleChange} />
                                                                <label htmlFor="chkContactIsMaster">{translate('customer.customerContactIsMaster')}</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="m-t-30">
                                                                <input id="chkContactIsActive" name="contacts.contact.isActive" type="checkbox" checked={this.state.contacts.contact.isActive} onChange={this.handleChange} />
                                                                <label htmlFor="chkContactIsActive">{translate('bank.bankIsActive')}</label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row clearfix">

                                                        <div className="col-md-3">
                                                            <div className="input-group">
                                                                <label className="validationRequired" htmlFor="txtContactPhoneNumber">{translate('bank.bankContactPhoneNumber')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactPhoneNumber" length="15" className="form-control js-change js-phone-number" name="contacts.contact.phoneNumber" placeholder={translate('bank.bankContactPhoneNumberPlaceHolder')} type="text" />
                                                                </div>

                                                                {this.contactValidator.message('contacts.contact.phoneNumber', this.state.contacts.contact.phoneNumber, 'required', false, { default: translate('bank.bankContactPhoneNumberRequired') })}
                                                            </div>
                                                        </div>


                                                        <div className="col-md-5">
                                                            <div className="input-group">
                                                                <label htmlFor="txtContactEmails">{translate('bank.bankContactEmails')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactEmails" className="form-control js-change" name="contacts.contact.emails" placeholder={translate('bank.bankContactEmailsPlaceHolder')} onKeyUp={this.handleTypingEmail} type="text" />
                                                                </div>

                                                                {this.contactValidator.message('contacts.contact.emails', this.state.contacts.contact.emails, 'emails', false, { default: translate('bank.bankContactEmailsInvalid') })}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <label htmlFor="txtContactNotes">{translate('bank.bankContactNotes')}</label>

                                                                <div className="form-line">
                                                                    <input id="txtContactNotes" className="form-control js-change" name="contacts.contact.notes" placeholder={translate('bank.bankContactNotesPlaceHolder')} type="text" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row clearfix">
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn m-r-10" onClick={this.resetContact} style={{ display: this.state.contacts.rowIndex >= 0 ? 'inline-block' : 'none' }}>
                                                                <i className="material-icons">&#xe5c4;</i>
                                                                <span>{translate('forms.buttonCancel')}</span>
                                                            </button>

                                                            <button type="button" className="btn btn-primary" onClick={this.addContact}>
                                                                <i className="material-icons">&#xe145;</i>
                                                                <span>{translate(this.state.contacts.rowIndex < 0 ? 'bank.bankContactsAddNew' : 'bank.bankContactsUpdate')}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <ReactTable
                                            data={this.state.bank.contact}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: translate('bank.bankContactName'),
                                                            accessor: "name",
                                                        },
                                                        {
                                                            Header: translate('bank.bankContactPosition'),
                                                            accessor: "position",
                                                        },
                                                        {
                                                            Header: translate('bank.bankContactPhoneNumber'),
                                                            accessor: "phoneNumber",
                                                        },
                                                        {
                                                            Header: translate('bank.bankContactEmails'),
                                                            accessor: "emails",
                                                        },
                                                        {
                                                            Header: translate('bank.bankContactNotes'),
                                                            accessor: "notes",
                                                        },
                                                        {
                                                            Header: translate('bank.bankContactIsMaster'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isMaster &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isMaster &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: translate('bank.bankIsActive'),
                                                            Cell: row => {
                                                                return <div className="align-center">

                                                                    {row.original.isActive &&
                                                                        <i className="material-icons color-success">&#xe86c;</i>
                                                                    }
                                                                    {!row.original.isActive &&
                                                                        <i className="material-icons color-danger">&#xe5c9;</i>
                                                                    }

                                                                </div>
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        session.auth([{ type: "Bank", value: "Save" }]) &&
                                                        {
                                                            Cell: row => (
                                                                <div className="align-center">
                                                                    <button className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10" type="button" onClick={() => { this.deleteContact(row.index) }}>
                                                                        <i className="material-icons">&#xe872;</i>
                                                                    </button>

                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.editContact(row.index) }}>
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </div>
                                                            ),
                                                            maxWidth: 150
                                                        }
                                                    ]
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight m-b-40"
                                            previousText={translate('forms.previousText')}
                                            nextText={translate('forms.nextText')}
                                            noDataText={translate('forms.noDataText')}
                                            pageText={translate('forms.pageText')}
                                            ofText={translate('forms.ofText')}
                                            rowsText={translate('forms.rowsText')}
                                        />
                                    </div>

                                </div>

                                <div className="row clearfix">
                                    <div className="col-md-12 formActions">
                                        <Link to="/banks" className="btn btn-default waves-effect">
                                            <i className="material-icons">&#xe5c4;</i>
                                            <span>{translate('forms.buttonReturn')}</span>
                                        </Link>

                                        {session.auth([{ type: "Bank", value: "Save" }]) &&
                                            <button type="button" className="btn btn-success waves-effect" disabled={this.state.submitLoading} onClick={this.handleSubmit}>
                                            <i className="material-icons">&#xe161;</i>
                                                <span>{translate('forms.buttonSave')}</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ToastContainer hideProgressBar />
                </div>
            )

        } else {

            return (
                <div className="preloader pl-size-lg align-center">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>

                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {

    function handleClick() {

        tabToShow.trigger('click');
        closeToast();
    }

    return (

        <div onClick={handleClick}>
            {message}
        </div>
    );
}

export default BankPage;