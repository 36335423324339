import React from 'react';
import Modal from 'react-responsive-modal';
import { translate } from 'react-internationalization';
import QueryString from 'query-string';
import $ from 'jquery';
import * as session from '../components/SessionValidator';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js';

export default class DocumentViewPage extends React.Component {

	constructor(props) {

		super(props);
		if (!this.props.match.params.id) this.props.history.push('/home');

		this.id = this.props.match.params.id;
		this.token = session.getToken();

		this.state = {

			numberOfPages: null,
			pageNumber: 1,
			scale: 1.5,
			document: null
		}
	}

	onDocumentLoad = ({ numPages }) => { this.setState({ numberOfPages: numPages }); }

	nextPageDocument() {

		if (this.state.pageNumber < this.state.numberOfPages) {

			this.state.pageNumber = this.state.pageNumber + 1;
			this.setState(this.state);
		}
	}

	previousPageDocument() {

		if (this.state.pageNumber > 1) {

			this.state.pageNumber = this.state.pageNumber - 1;
			this.setState(this.state);
		}
	}

	changeScale(plus) {

		let scale = this.state.scale;

		if (plus && scale < 5) {

			scale += .5;
			this.setState({ scale: scale });

		} else if (!plus && scale > 1.5) {

			scale -= .5;
			this.setState({ scale: scale });
		}
	}

	scrollDocument() {

		$('.react-pdf__Page').off('mousedown').on('mousedown', function (evt) {

			let start = evt.pageX, elm = $(this), startPos = elm.scrollLeft();
			elm.mousemove(function (evt) { elm.scrollLeft(startPos + (start - evt.pageX)); return false; });

			elm.one('mouseup', function () { elm.off('mousemove'); });
			evt.stopPropagation();

			return false;
		});
	}

	async componentDidMount() {
				
		if (!this.id) return;

		await fetch(window.$DocumentServiceURL + '/api/document/' + this.id + "?withFile=true",
			{
				method: 'GET', // *GET, POST, PUT, DELETE, etc.
				mode: 'cors', // no-cors, *cors, same-origin
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				credentials: 'same-origin', // include, *same-origin, omit
				headers: {
					'Content-Type': 'application/json',
					'Authorization': this.token
				},
				redirect: 'follow', // manual, *follow, error
				referrer: 'no-referrer' // no-referrer, *client
			})
			.then(res => {

				if (res.status === 401) {
					session.sessionExpired();
					res = [];

					return res;
				}

				if (res.status === 403) {
					session.accessDenied();
					res = [];

					return res;
				}

				return res.json();

			})
			.then(res => {

				this.setState({ document: res.data });
			});
	}

	componentDidUpdate() {

		this.scrollDocument();
	}

	render() {

		if (this.state.document !== null) {

			return (
				<div className="row">
					<div className="col-xs-12">
						<div className="card">
							<div className="header">
								<h2>
									{translate('transaction.transactionContractTitle')}
									<small>{translate('transaction.transactionContractVisualize')}</small>
								</h2>
							</div>

							<div className="body">
								<div className="row clearfix">
									<div className="col-md-12">
										<div className="align-center">
											<div className="m-b-10">
												<button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.previousPageDocument() }}>
													<i className="material-icons">&#xe408;</i>
												</button>

												<button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-5" onClick={() => { this.changeScale(false) }}>
													<i className="material-icons">&#xe15b;</i>
												</button>

												<button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-5" onClick={() => { this.changeScale(true) }}>
													<i className="material-icons">&#xe145;</i>
												</button>

												<button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-10" onClick={() => { this.nextPageDocument() }}>
													<i className="material-icons">&#xe409;</i>
												</button>
											</div>

											<Document
												file={`data:application/pdf;base64,${this.state.document.file}`}
												onLoadSuccess={this.onDocumentLoad}>
												<Page pageNumber={this.state.pageNumber} scale={this.state.scale} />
											</Document>

											{
												this.state.numberOfPages
												&&
												<p className="m-t-20">
													{
														translate('document.documentPaginationPage') + ' ' +
														this.state.pageNumber + ' ' +
														translate('document.documentPaginationOf') + ' ' +
														this.state.numberOfPages
													}
												</p>
											}

											<div className="m-t-10">
												<button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.previousPageDocument() }}>
													<i className="material-icons">&#xe408;</i>
												</button>

												<button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-5" onClick={() => { this.changeScale(false) }}>
													<i className="material-icons">&#xe15b;</i>
												</button>

												<button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-5" onClick={() => { this.changeScale(true) }}>
													<i className="material-icons">&#xe145;</i>
												</button>

												<button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-10" onClick={() => { this.nextPageDocument() }}>
													<i className="material-icons">&#xe409;</i>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)

		} else {

			return (
				<div className="preloader pl-size-lg align-center">
					<div className="spinner-layer">
						<div className="circle-clipper left">
							<div className="circle"></div>
						</div>

						<div className="circle-clipper right">
							<div className="circle"></div>
						</div>
					</div>
				</div>
			)
		}
	}
}
