import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { translate } from 'react-internationalization';
import "react-table/react-table.css";
import * as session from '../components/SessionValidator';

class HomePage extends React.Component {

    constructor(props) {

        super(props);
        this.props = props;

        var auth = localStorage.getItem("authp");

        if (!auth) {
            if (!localStorage.getItem("loginExpired"))
                window.location.href = "/login";
            else
                session.validateSession();
        }
    }

    componentDidUpdate() {

        if (this.props.message && translate('forms.accessDenied') !== '') {

            session.accessDenied();
        }
    }

    isLogged() {
        return localStorage.getItem('name') !== null;
    }

    render() {
        
        return (
            <div>
                <ToastContainer hideProgressBar />
            </div>
        );

    }
}

export default HomePage;
