import { translate } from 'react-internationalization';

export function getStatus() {

    let list = [

        { text: translate('enums.transactionStatus.pending'), index: 0 },
        { text: translate('enums.transactionStatus.approved'), index: 1 },
        { text: translate('enums.transactionStatus.processed'), index: 2 },
        { text: translate('enums.transactionStatus.expired'), index: 3 },
        { text: translate('enums.transactionStatus.canceled'), index: 4 },
        { text: translate('enums.transactionStatus.finished'), index: 5 },
        { text: translate('enums.transactionStatus.refused'), index: 6 }

    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}