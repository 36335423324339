import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { translate } from 'react-internationalization';
import ReactTable from "react-table";
import * as session from '../components/SessionValidator';

class CustomersPage extends React.Component {

    displayName = CustomersPage.name;

    constructor(props) {

        super(props);

        this.token = session.getToken();

        this.handleChange = this.handleChange.bind(this);

        this.state = {

            customers: [],
            loading: true,
            search: ''
        };
    }

    async componentDidMount() {

        let token = this.token;

        fetch(window.$CustomerServiceURL + '/api/Customer/List',
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer' // no-referrer, *client
            })
            .then(res => {

                if (res.status === 401) {
                    session.sessionExpired();
                    res = [];
                    return res;
                }

                if (res.status === 403) {
                    session.accessDenied();
                    res = [];
                    return res;
                }

                return res.json();
            })
            .then(res => {

                if (res.data)
                    res.data = res.data.sort(function (a, b) { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; });

                this.setState({ customers: res.data, loading: false });
            });
    }

    handleChange(event) {

        session.validateSession();

        this.setState({ search: event.target.value });
    }

    render() {

        var search = this.state.search.toLowerCase();

        var customerItems = [];

        if (this.state.customers != null) {
            var customerItems = this.state.customers.filter(function (item) {

                return item.id.toString().toLowerCase().indexOf(search) >= 0 || item.name.toLowerCase().indexOf(search) >= 0;

            });
        }

        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                {translate('customer.customersTitle')}
                                <small>{translate('customer.customersTitleDescription')}</small>
                            </h2>
                        </div>

                        <div className="body">
                            <div className="row clearfix m-t-20">
                                <div className="col-md-9">
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                            <i className="material-icons">&#xe8b6;</i>
                                        </span>

                                        <div className="form-line">
                                            <input name="search" className="form-control" placeholder={translate('forms.searchBar')} type="text" value={this.state.search} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>

                                {(session.isAdmin() || session.isOperator()) && session.auth([{ type: "Customer", value: "Save" }]) &&
                                    <div className="col-md-3">
                                        <Link to={"/customer"}>
                                            <button type="button" className="btn btn-block btn-primary right">
                                            <i className="material-icons">&#xe145;</i>
                                                <span>{translate('customer.customersAddNew')}</span>
                                            </button>
                                        </Link>
                                    </div>
                                }
                            </div>

                            <ReactTable
                                data={customerItems}
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                maxWidth: 60
                                            },
                                            {
                                                Header: translate('customer.customerName'),
                                                accessor: "name"
                                            },
                                            {
                                                Header: translate('customer.customerIsActive'),
                                                Cell: row => {
                                                    return <div className="align-center">

                                                        {row.original.isActive &&
                                                            <i className="material-icons color-success">&#xe86c;</i>
                                                        }
                                                        {!row.original.isActive &&
                                                            <i className="material-icons color-danger">&#xe5c9;</i>
                                                        }

                                                    </div>
                                                },
                                                maxWidth: 100
                                            },
                                            (session.isAdmin() || session.isOperator()) && session.auth([{ type: "Customer", value: "Get" }]) &&
                                            {
                                                Cell: row => (
                                                    <div className="align-right">
                                                        <Link to={"/customer/" + row.original.id}>
                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" title={translate('forms.buttonEdit')}>
                                                                <i className="material-icons">&#xe3c9;</i>
                                                            </button>
                                                        </Link>

                                                        <Link to={"/customersuppliers/" + row.original.id}>
                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" title={translate('supplier.suppliersTitle')}>
                                                                <i className="material-icons">&#xe7f1;</i>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                ),
                                                maxWidth: 200
                                            }
                                        ]
                                    }
                                ]}
                                defaultPageSize={10}
                                className="-striped -highlight"
                                loading={this.state.loading}
                                previousText={translate('forms.previousText')}
                                nextText={translate('forms.nextText')}
                                noDataText={translate('forms.noDataText')}
                                pageText={translate('forms.pageText')}
                                ofText={translate('forms.ofText')}
                                rowsText={translate('forms.rowsText')}
                            />
                        </div>
                    </div>
                </div>

                <ToastContainer hideProgressBar />
            </div>
        )

    }

}

export default CustomersPage;