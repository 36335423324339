import React from 'react';
import ReactDOM from 'react-dom';

import { CookiesProvider } from 'react-cookie';

import {
    InternationalizationProvider,
    internationalize
} from 'react-internationalization'

import * as languages from '../src/translations';

import './include/bootstrap';
import './components/admin';
import './App.css';

import App from './App';
import registerServiceWorker from './registerServiceWorker';

//origem
window.$ClientId = 'SBTur'

window.$AppTitle = 'SmartAntecipa'
window.$AppURL = 'https://smartantecipa.com.br/'

//URL Services

//Server prod
window.$AccountServiceURL = 'https://api.portal.smartantecipa.com.br/account/service'
window.$BankServiceURL = 'https://api.portal.smartantecipa.com.br/bank/service'
window.$CustomerServiceURL = 'https://api.portal.smartantecipa.com.br/customer/service'
window.$DocumentServiceURL = 'https://api.portal.smartantecipa.com.br/document/service'
window.$LogServiceURL = 'https://api.portal.smartantecipa.com.br/log/service'
window.$SupplierServiceURL = 'https://api.portal.smartantecipa.com.br/supplier/service'
window.$TransactionServiceURL = 'https://api.portal.smartantecipa.com.br/transaction/service'

//URL Services

const InternationalizedApp = internationalize(App)

ReactDOM.render(

    <InternationalizationProvider defaultLanguage="pt" languages={languages} dynamicImports>
        <CookiesProvider >
            <InternationalizedApp />
        </CookiesProvider>
    </InternationalizationProvider>,

    document.getElementById('root')
)

registerServiceWorker();


