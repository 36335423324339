import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { translate } from 'react-internationalization';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-toastify/dist/ReactToastify.css';
import * as enums from '../components/enums/signatoryType';
import ModalSignDocument from '../components/transaction/modal-sign-document';

pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js';

const moment = window.Moment;
const $ = window.jQuery;

class SignPage extends React.Component {

    constructor(props) {
        super(props);

        this.id = this.props.match.params.id;

        this.state = {
            loading: true,

            reportDailyLog: {
                startDate: moment().format(),
                objectAfter: '',
                objectBefore: ''
            },

            numberOfPages: null,
            pageNumber: 1,
            scale: 1.5,
            document: null,
            signatory: {},
            openSign: false,
        };
    }

    print() {
        var winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
            'resizable,screenX=50,screenY=50,width=850,height=1050';

        var htmlPop = '<embed width=100% height=100%'
            + ' type="application/pdf"'
            + ' src="data:application/pdf;base64,'
            + escape(this.state.document)
            + '"></embed>';

        var printWindow = window.open("", "PDF", winparams);
        printWindow.document.write(htmlPop);
        printWindow.print();
    }

    onDocumentLoad = ({ numPages }) => { this.setState({ numberOfPages: numPages }); }

    nextPageDocument() {

        if (this.state.pageNumber < this.state.numberOfPages) {

            this.state.pageNumber = this.state.pageNumber + 1;
            this.setState(this.state);
        }
    }

    previousPageDocument() {

        if (this.state.pageNumber > 1) {

            this.state.pageNumber = this.state.pageNumber - 1;
            this.setState(this.state);
        }
    }

    changeScale(plus) {

        let scale = this.state.scale;

        if (plus && scale < 5) {

            scale += .5;
            this.setState({ scale: scale });

        } else if (!plus && scale > 1.5) {

            scale -= .5;
            this.setState({ scale: scale });
        }
    }

    scrollDocument() {

        $('.react-pdf__Page').off('mousedown').on('mousedown', function (evt) {

            let start = evt.pageX, elm = $(this), startPos = elm.scrollLeft();
            elm.mousemove(function (evt) { elm.scrollLeft(startPos + (start - evt.pageX)); return false; });

            elm.one('mouseup', function () { elm.off('mousemove'); });
            evt.stopPropagation();

            return false;
        });
    }

    async componentDidMount() {

        $('#leftsidebar').hide();
        $('#mainNav').hide();
        $('#userData').hide();
        $('.content').removeClass('content');

        if (this.id) {

            await fetch(window.$TransactionServiceURL + '/api/transaction/sign/' + this.id,
                {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrer: 'no-referrer' // no-referrer, *client
                })
                .then(res => {

                    return res.json();
                })
                .then(res => {

                    this.setState({ loading: false });

                    if (res.data) {

                        this.setState({ signatory: res.data, document: res.data.fileOriginal });
                    }

                    this.forceUpdate();
                });
        }
    }

    componentWillUnmount() {

    }

    componentDidUpdate() {

        this.scrollDocument();
    }

    downloadSignedContract() {

        confirmAlert({

            buttons: [
                {
                    label: translate('forms.buttonOk'),
                    onClick: () => {}
                }
            ],
            message: translate('transaction.transactionDownloadDocumentTitle'),
            title: translate('transaction.transactionDownloadDocumentMessage')

        });
    }

    handleChangeOpenSign(signer) {

        this.state.openSign = true;
        this.state.signer = signer;
        this.forceUpdate();
    }

    render() {

        if (this.state.document !== null) {

            return (
                <div>
                    <div className="row">

                        <nav className="navbar" id="mainNav">

                            <div className="container-fluid">
                                <div className="navbar-header">
                                    <img src="logo.png" width="110" alt="" />
                                </div>
                            </div>

                            <div className="container-fluid right m-t-15 m-r-15">
                                {!this.state.signatory.status &&
                                    <button className="btn btn-warning waves-effect" type="button" onClick={() => { this.handleChangeOpenSign(this.state.signatory) }}>
                                    <i className="material-icons">&#xe862;</i>
                                        <span>{translate('document.documentSign')}</span>
                                    </button>
                                }
                            </div>

                        </nav>

                        <section className="content" style={{ marginLeft: 15 }}>
                            <div>
                                <div className="col-xs-12">
                                    <div className="card">
                                        <div className="header">
                                            <h2>
                                                <ul className="nav tab-nav-right">
                                                    <li className="left">
                                                        {translate('document.documentName')}: {this.state.signatory.fileName}

                                                        {this.state.signatory.status &&
                                                            <small>
                                                                {translate('transaction.transactionSignedContract')}: {window.Moment(this.state.signatory.signatureDate, 'YYYY-MM-DD h:mm:ss a').format('DD/MM/YYYY h:mm:ss a')}
                                                            </small>
                                                        }

                                                        {!this.state.signatory.status &&
                                                            <small>
                                                                {parseInt(this.state.signatory.type) !== 5 ? enums.getTypes().find(x => { return x.index === parseInt(this.state.signatory.type); }).text
                                                                    : translate('transaction.transactionSignContract')
                                                                }
                                                            </small>
                                                        }
                                                    </li>
                                                    <li className="right">
                                                        <div className="col-md-6 col-xs-12">
                                                            <small>
                                                                <i className="material-icons" style={{ position: 'relative', top: '5px', paddingRight: '10px' }}>&#xe7fb;</i>
                                                                <span>{this.state.signatory.signatureCount}/{this.state.signatory.signatoryCount} {translate('transaction.transactionSubscriptions')}</span>
                                                                <a href="javascript:void(0);" onClick={() => { this.print() }}>
                                                                    <i className="material-icons" style={{ position: 'relative', top: '5px', paddingLeft: '10px' }}>&#xe8ad;</i>
                                                                </a>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 col-xs-12">
                                                            <div className="btn-group" role="group">
                                                                <button id="btnDownload" type="button" className="btn bg-deep-purple waves-effect dropdown-toggle" data-toggle="dropdown">
                                                                    <i className="material-icons">&#xe2c0;</i>
                                                                    <span>{translate('forms.downloadDocument')}</span>
                                                                    <span className="caret"></span>
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="btnDownload">
                                                                    <li>
                                                                        <a href={window.$DocumentServiceURL + "/api/Document/Download/" + this.state.signatory.fileHashOriginalContract} className="waves-effect waves-block">{translate('forms.downloadOriginalDocument')}</a>
                                                                    </li>
                                                                    <li>
                                                                        {!this.state.signatory.fileHashSignedContract &&
                                                                            <a href="javascript:void(0);" onClick={() => { this.downloadSignedContract() }} className="waves-effect waves-block">{translate('forms.downloadSignedDocument')}</a>
                                                                        }

                                                                        {this.state.signatory.fileHashSignedContract &&
                                                                            <a href={window.$DocumentServiceURL + "/api/Document/Download/" + this.state.signatory.fileHashSignedContract} className="waves-effect waves-block">{translate('forms.downloadSignedDocument')}</a>
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </h2>
                                        </div>

                                        <div className="body">
                                            <div className="row clearfix">
                                                <div className="col-md-12">
                                                    <div className="align-center">

                                                        <div className="m-b-10">
                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.previousPageDocument() }}>
                                                                <i className="material-icons">&#xe408;</i>
                                                            </button>

                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-5" onClick={() => { this.changeScale(false) }}>
                                                                <i className="material-icons">&#xe15b;</i>
                                                            </button>

                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-5" onClick={() => { this.changeScale(true) }}>
                                                                <i className="material-icons">&#xe145;</i>
                                                            </button>

                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-10" onClick={() => { this.nextPageDocument() }}>
                                                                <i className="material-icons">&#xe409;</i>
                                                            </button>
                                                        </div>

                                                        {
                                                            this.state.document
                                                            &&
                                                            <Document
                                                                file={`data:application/pdf;base64,${this.state.document}`}
                                                                onLoadSuccess={this.onDocumentLoad}>
                                                                <Page pageNumber={this.state.pageNumber} scale={this.state.scale} />
                                                            </Document>
                                                        }

                                                        {
                                                            this.state.numberOfPages
                                                            &&
                                                            <p className="m-t-20">
                                                                {
                                                                    translate('document.documentPaginationPage') + ' ' +
                                                                    this.state.pageNumber + ' ' +
                                                                    translate('document.documentPaginationOf') + ' ' +
                                                                    this.state.numberOfPages
                                                                }
                                                            </p>
                                                        }

                                                        <div className="m-t-10">
                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10" onClick={() => { this.previousPageDocument() }}>
                                                                <i className="material-icons">&#xe408;</i>
                                                            </button>

                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-5" onClick={() => { this.changeScale(false) }}>
                                                                <i className="material-icons">&#xe15b;</i>
                                                            </button>

                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-5" onClick={() => { this.changeScale(true) }}>
                                                                <i className="material-icons">&#xe145;</i>
                                                            </button>

                                                            <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-10" onClick={() => { this.nextPageDocument() }}>
                                                                <i className="material-icons">&#xe409;</i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-t-20">

                                </div>

                            </div>
                        </section>

                    </div>

                    <ModalSignDocument
                        signer={this.state.signatory}
                        openSign={this.state.openSign}
                        id={this.id}
                        closeSign={() => {
                            this.setState({ openSign: false });
                            this.forceUpdate();
                        }}
                        toSign={event => {
                            this.setState({ signatory: event });
                            this.forceUpdate();
                        }}
                    />

                </div>
            )
        }
        else {

            return (

                <div className="preloader pl-size-lg align-center m-t-20 m-l-20">
                    <div className="spinner-layer">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>

                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default SignPage
