import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { translate } from 'react-internationalization';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as session from '../components/SessionValidator';

const $ = window.jQuery;

class LoginPage extends React.Component {
    displayName = LoginPage.name
    constructor(props) {
        super(props);

        this.state = {
            user: { email: '', password: '' }, msg: '', loading: false
        };

        this.sendAuth = this.sendAuth.bind(this);
        this.setToken = this.setToken.bind(this);

        localStorage.removeItem('loginExpired');

        if (localStorage.getItem('email') != null) {

            localStorage.removeItem("authp");

            localStorage.removeItem('userId');
            localStorage.removeItem('email');
            localStorage.removeItem('name');

            localStorage.removeItem('customerId');
            localStorage.removeItem('supplierId');
            localStorage.removeItem('bankId');

            localStorage.removeItem('ia');// is admin
            localStorage.removeItem('io');// is operator

            localStorage.removeItem('t');// type user

            localStorage.removeItem('r');// roles
            localStorage.removeItem('userId');
        }
    }

    setToken(token) {
        var sessionTimeOut = new Date();

        sessionTimeOut.setHours(sessionTimeOut.getHours() + 2);
        localStorage.setItem('sessionTimeOut', sessionTimeOut);
        localStorage.setItem("authp", token);

        this.setState({ token: token });
    }

    componentDidMount() {
        $('#leftsidebar').hide();
        $('.content').removeClass('content');
        $('#mainNav').hide();
    }

    componentWillUnmount() {
        $('#mainNav').slideDown('fast');
    }

    sendAuth(event) {
        event.preventDefault();
        let resStatus = 0;

        if (this.email.value === '') {
            toast.error(translate('login.loginEmailRequired'), {
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            return;
        }

        if (this.password.value === '') {
            toast.error(translate('login.loginPasswordRequired'), {
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            return;
        }

        if (localStorage.getItem('clickHour')) localStorage.removeItem('clickHour');

        this.setState({ loading: true });

        fetch(window.$AccountServiceURL + '/api/Auth', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'ClientId': window.$ClientId
            },
            credentials: 'omit',
            body: JSON.stringify({ email: this.email.value.trim(), password: this.password.value })
        }).then(response => {
            resStatus = response.status
            return response.json()
        }).then(res => {
            switch (resStatus) {
                case 200:

                    let response = res.data;

                    this.setToken(response.token);

                    localStorage.setItem('userId', response.userId);
                    localStorage.setItem('email', response.email);
                    localStorage.setItem('name', response.name);

                    localStorage.setItem('customerId', response.customerId);
                    localStorage.setItem('supplierId', response.supplierId);
                    localStorage.setItem('bankId', response.bankId);

                    localStorage.setItem('ia', response.isAdmin);// is admin
                    localStorage.setItem('io', response.isOperator);// is operator

                    localStorage.setItem('t', response.type);// type user

                    localStorage.setItem('r', JSON.stringify(response.roles));// roles

                    window.location.href = '/';
                    
                    break;
                case 400:

                    var messages = res.data;

                    toast.error(messages[0].message, {
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });

                    break
                case 422:

                    let respMessages = res.data;

                    localStorage.setItem('email', this.email.value.trim());

                    let attempts = respMessages[0].parameter + ' de 5 tentativas. Após 5 tentativas, este usuário será bloqueado';

                    toast.error(respMessages[0].message, {
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });

                    toast.error(attempts, {
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });

                    break
                case 500:
                    break;

                case 423:

                    session.userLocked();

                    break;
            }

            this.setState({ loading: false });
        })
    }

    render() {

        var divStyle = {
            display: this.state.loading ? 'block' : 'none'
        };

        return (
            <section className="login-wrap">
                <div className="login-page bg-deep-purple">
                    <div id="loginBox" className="login-box">
                        <div className="logo">
                            <a><img src="logo.png"/></a>
                            <small></small>

                        </div>
                        <div className="card">
                            <div className="body">
                                <form method="POST" onSubmit={this.sendAuth}>
                                    <div className="msg"><h2></h2></div>
                                    <span>{this.state.msg}</span>
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                            <i className="material-icons">&#xe0be;</i>
                                        </span>
                                        <div className="form-line">
                                            <input type="text" id="txtEmail" className="form-control" name="email" placeholder={translate('login.loginEmail')} autoFocus ref={(input) => this.email = input} autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                            <i className="material-icons">&#xe897;</i>
                                        </span>
                                        <div className="form-line">
                                            <input type="password" className="form-control" name="password" placeholder={translate('login.loginPassword')} ref={(input) => this.password = input} />
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-md-8">
                                            <div className="preloader pl-size-xs" style={divStyle}>
                                                <div className="spinner-layer">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div>
                                                    <div className="circle-clipper right">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <button className="btn bg-deep-purple" type="submit">
                                                <span>{translate('login.loginSignIn')} </span>
                                                <i className="material-icons">&#xe5c8;</i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
                <ToastContainer hideProgressBar />
            </section>
        )
    }
}

export default LoginPage
