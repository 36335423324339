import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { translate } from 'react-internationalization';
import Routes from './Routes';
import * as session from './components/SessionValidator';

export default class App extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

            collapsed: false,
            name: localStorage.getItem('name'),
            email: localStorage.getItem('email'),
            showMenu: true
        };

        this.handleGlobalClick = this.handleGlobalClick.bind(this);
        this.hideShowMenu = this.hideShowMenu.bind(this);
    }

    isLogged() {

        return localStorage.getItem('name') !== null;
    }

    componentDidMount() {

        window.onbeforeunload = function () {
            localStorage.removeItem('loginExpired');
            return undefined;
        };
    }

    handleGlobalClick(e) {
        if (window.location.href.indexOf('sign') == -1 && window.location.href.indexOf('login') == -1 && window.location.href.indexOf('logout') == -1 && document.location.pathname != "/")
            session.validateSession();
    }

    hideShowMenu(e) {

        if (this.state.showMenu == true)
            this.setState({ showMenu: false });
        else
            this.setState({ showMenu: true });
    }

    render() {

        return (

            <Router>
                <div className="theme-deep-purple" onClick={this.handleGlobalClick}>
                    <nav className="navbar" id="mainNav">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <a href="javascript:void(0);" className="bars"></a>
                                <a className="navbar-brand" href="/"><img src="logo.png" width="110" alt="" /></a>
                            </div>
                        </div>
                        <div className="user-info">
                            <div className="image">
                            </div>

                            {
                                this.isLogged()
                                &&
                                <div id="userData" className="info-container">
                                    <div className="name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.state.name}</div>
                                    <div className="email" title={this.state.email}>{this.state.email}</div>

                                    <div className="btn-group user-helper-dropdown">
                                        <i className="material-icons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">&#xe313;</i>

                                        <ul className="dropdown-menu pull-right">
                                            <li>
                                                <Link to="/changepass/">
                                                    <i className="material-icons">&#xe897;</i>
                                                    {translate('menu.login.changePassword')}
                                                </Link>
                                            </li>

                                            <li role="seperator" className="divider"></li>

                                            <li>
                                                <Link to="/logout/">
                                                    <i className="material-icons">&#xe890;</i>
                                                    {translate('menu.login.signOut')}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                    </nav>

                    <section className={this.state.showMenu ? "closedMenu" : "openedMenu"}>
                        <div className="icon-show-menu" onClick={this.hideShowMenu}>
                            <i className="material-icons">&#xe5c8;</i>
                        </div>
                        <aside id="leftsidebar" className="sidebar">

                            <div className="icon-hidden-menu" onClick={this.hideShowMenu}>
                                <i className="material-icons">&#xe5c4;</i>
                            </div>

                            <div className="menu">
                                <ul className="list">
                                    <li>
                                        <Link to="/">
                                            <i className="material-icons">&#xe88a;</i>
                                            <span>{translate('menu.home.home')}</span>
                                        </Link>
                                    </li>
                                    {
                                        (session.isAdmin() || session.isOperator() || session.isCustomer())
                                        && session.auth([{ type: "Customer", value: "List" }])
                                        &&
                                        <li>
                                            <a href="javascript:void(0);" className="menu-toggle">
                                                <i className="material-icons">&#xe937;</i>
                                                <span>{translate('menu.customer.customer')}</span>
                                            </a>

                                            <ul className="ml-menu">
                                                {
                                                    (session.isAdmin() || session.isOperator()) &&
                                                    <li>
                                                        <Link to="/customers/">
                                                            <i className="material-icons">&#xe91a;</i>
                                                            <span>{translate('menu.customer.customers')}</span>
                                                        </Link>
                                                    </li>
                                                }

                                                {
                                                    session.isCustomer() &&
                                                    <li>
                                                        <Link to={"/customer/" + session.getCustomerId()}>
                                                            <i className="material-icons">&#xe91a;</i>
                                                            <span>{translate('menu.customer.informations')}</span>
                                                        </Link>
                                                    </li>
                                                }

                                            </ul>
                                        </li>
                                    }
                                    {
                                        session.auth([{ type: "Supplier", value: "List" }])
                                        &&
                                        <li>
                                            <a href="javascript:void(0);" className="menu-toggle">
                                                <i className="material-icons">&#xe7f1;</i>
                                                <span>{translate('menu.supplier.supplier')}</span>
                                            </a>

                                            <ul className="ml-menu">
                                                {
                                                    (session.isAdmin() || session.isOperator() || session.isBank() || session.isCustomer()) &&
                                                    <li>
                                                        <Link to="/suppliers/">
                                                            <i className="material-icons">&#xe7f9;</i>
                                                            <span>{translate('menu.supplier.suppliers')}</span>
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    session.isCustomer() &&
                                                    <li>
                                                        <Link to={"/customersuppliers/" + session.getCustomerId()}>
                                                            <i className="material-icons">&#xe91a;</i>
                                                            <span>{translate('menu.supplier.classifications')}</span>
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    session.isSupplier() &&
                                                    <li>
                                                        <Link to={"/supplier/" + session.getSupplierId()}>
                                                            <i className="material-icons">&#xe91a;</i>
                                                            <span>{translate('menu.supplier.informations')}</span>
                                                        </Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    }
                                    {
                                        session.auth([{ type: "Bank", value: "List" }])
                                        &&
                                        <li>
                                            <a href="javascript:void(0);" className="menu-toggle">
                                                <i className="material-icons">&#xe0af;</i>
                                                <span>{translate('menu.bank.bank')}</span>
                                            </a>

                                            <ul className="ml-menu">
                                                {(session.isAdmin() || session.isOperator()) &&
                                                    <li>
                                                        <Link to="/banks/">
                                                        <i className="material-icons">&#xe0af;</i>
                                                            <span>{translate('menu.bank.banks')}</span>
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    session.isBank() &&
                                                    <li>
                                                        <Link to={"/bank/" + session.getBankId()}>
                                                            <i className="material-icons">&#xe0af;</i>
                                                            <span>{translate('menu.bank.informations')}</span>
                                                        </Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    }
                                    {
                                        session.auth([{ type: "Invoice", value: "List" }])
                                        &&
                                        <li>
                                            <a href="javascript:void(0);" className="menu-toggle">
                                                <i className="material-icons">&#xe263;</i>
                                                <span>{translate('menu.receivables.receivables')}</span>
                                            </a>

                                            <ul className="ml-menu">

                                                {
                                                    <li>
                                                        <Link to="/invoices/">
                                                            <i className="material-icons">&#xe86d;</i>
                                                            <span>{translate('menu.receivables.invoices')}</span>
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    //(session.isAdmin() || session.isOperator() || session.isSupplier())
                                                    //&&
                                                    //<li>
                                                    //    <Link to="/receivables/">
                                                    //        <i className="material-icons">&#xe86d;</i>
                                                    //        <span>{translate('menu.receivables.summary')}</span>
                                                    //    </Link>
                                                    //</li>
                                                }
                                            </ul>
                                        </li>
                                    }
                                    {
                                        session.auth([{ type: "Transaction", value: "List" }])
                                        &&
                                        <li>
                                            <a href="javascript:void(0);" className="menu-toggle">
                                                <i className="material-icons">&#xe933;</i>
                                                <span>{translate('menu.transactions.transactions')}</span>
                                            </a>

                                            <ul className="ml-menu">
                                                {
                                                    (session.isAdmin() || session.isOperator() || session.isSupplier())
                                                    &&
                                                    <li>
                                                        <Link to="/transaction/">
                                                            <i className="material-icons">&#xe86d;</i>
                                                            <span>{translate('menu.transactions.anticipation')}</span>
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    <li>
                                                        <Link to="/transactions/">
                                                            <i className="material-icons">&#xe227;</i>
                                                            <span>{translate('menu.transactions.summary')}</span>
                                                        </Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    }

                                    {
                                        (session.isAdmin() || session.isOperator() || session.isCustomer())
                                        &&
                                        <li>
                                            <a href="javascript:void(0);" className="menu-toggle">
                                                <i className="material-icons">&#xe8b8;</i>
                                                <span>{translate('menu.administration.administration')}</span>
                                            </a>

                                            <ul className="ml-menu">
                                                {
                                                    session.auth([{ type: "Invoice", value: "Import" }])
                                                    &&
                                                    <li>
                                                        <Link to="/importNF/">
                                                            <i className="material-icons">&#xe0c3;</i>
                                                            <span>{translate('menu.administration.importNF')}</span>
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    session.isAdmin()
                                                    &&
                                                    <li>
                                                        <a href="javascript:void(0);" className="menu-toggle">
                                                            <i className="material-icons">&#xe853;</i>
                                                            <span>{translate('menu.administration.user.user')}</span>
                                                        </a>

                                                        <ul className="ml-menu">
                                                            {
                                                                <li>
                                                                    <Link to="/users/">
                                                                        <i className="material-icons">&#xe7fb;</i>
                                                                        <span>{translate('menu.administration.user.users')}</span>
                                                                    </Link>

                                                                </li>
                                                            }
                                                            {
                                                                <li>
                                                                    <Link to="/dailySummary/">
                                                                        <i className="material-icons">&#xe873;</i>
                                                                        <span>{translate('menu.administration.user.dailySummary')}</span>
                                                                    </Link>

                                                                </li>
                                                            }
                                                            {
                                                                <li className="itemMenuHeight80">
                                                                </li>
                                                            }
                                                        </ul>
                                                    </li>
                                                }
                                                {
                                                    session.isAdmin()
                                                    &&
                                                    <li>
                                                        <a href="javascript:void(0);" className="menu-toggle">
                                                            <i className="material-icons">&#xe8ad;</i>
                                                            <span>{translate('menu.administration.report.reports')}</span>
                                                        </a>

                                                        <ul className="ml-menu">

                                                            {
                                                                <li>
                                                                    <Link to="/suppliers/">
                                                                        <i className="material-icons">&#xe86d;</i>
                                                                        <span>{translate('menu.administration.report.approvedSuppliers')}</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {
                                                                <li>
                                                                    <Link to="/invoices/">
                                                                        <i className="material-icons">&#xe86d;</i>
                                                                        <span>{translate('menu.administration.report.financialCostsSupplier')}</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {
                                                                <li>
                                                                    <Link to="/invoices/">
                                                                        <i className="material-icons">&#xe86d;</i>
                                                                        <span>{translate('menu.administration.report.bankAssignments')}</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {
                                                                <li>
                                                                    <Link to="/invoices/">
                                                                        <i className="material-icons">&#xe86d;</i>
                                                                        <span>{translate('menu.administration.report.monthlyBankRevenue')}</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {
                                                                <li>
                                                                    <Link to="/invoices/">
                                                                        <i className="material-icons">&#xe86d;</i>
                                                                        <span>{translate('menu.administration.report.monthlyCustomerExpiration')}</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {
                                                                <li className="itemMenuHeight80">
                                                                </li>
                                                            }
                                                        </ul>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    }
                                </ul>
                            </div>

                            <div className="legal">
                                <div className="copyright">
                                    &copy; {new Date().getFullYear()} <a target="blank" href={window.$AppURL}>{window.$AppTitle}</a>
                                </div>
                            </div>
                        </aside>
                    </section>

                    <div className={this.state.showMenu ? " closedMenu" : "openedMenu"}>
                        <section className="content">
                            <div className="container-fluid">
                                <main>
                                    <Routes />
                                </main>
                            </div>
                        </section>
                    </div>
                </div>
            </Router >
        );
    }
}